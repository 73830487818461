import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  Navigation,
  Section1Title,
  Section2Slider,
  Section3About,
  Section4History,
  Section5WhereCan,
  Section5WhereCanTwo,
  Section6VillageReviews,
  Section7OtherOffers,
  Section9YandexMap,
} from '../../components/VillageSingle';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import {
  setLoading,
  setNewVillageReview,
  setVillageReviews,
} from '../../redux';
import { get } from 'lodash';
import { Breadcrumbs } from '../../components/GlobalStyledComponents';
import Navigation2 from '../../components/VillageSingle/Navigation2';

const Section23Style = styled.div`
  display: flex;
  margin-top: 18px;

  @media (max-width: 870px) and (min-width: 320px) {
    flex-direction: column;
  }
  & > div {
    &:nth-child(1) {
      width: calc(100% - 359px);
      margin-right: 24px;
      @media (max-width: 870px) and (min-width: 320px) {
        margin-right: 0;
        margin-bottom: 24px;
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 359px;
      @media (max-width: 870px) and (min-width: 320px) {
        width: 100%;
      }
    }
  }
`;
const SectionOthers = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    width: calc(100% - 359px);
    margin-right: 24px;
    @media (max-width: 920px) and (min-width: 320px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const Village = () => {
  const [recentViewed, setRecentViewed] = useState([]);
  const [show, setShow] = useState(false);
  const [village, setVillage] = useState({});
  const [modalOne, setModalOne] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const [rate, setRate] = useState(0);
  const [obj, setObj] = useState({});
  const [err, setErr] = useState({});

  const [hotelVisible, setHotelVisible] = useState(3);
  const [restaurantVisible, setRestaurantVisible] = useState(3);
  const [famousVisible, setFamousVisible] = useState(3);
  const [tourVisible, setTourVisible] = useState(3);
  const [craftVisible, setCraftVisible] = useState(3);
  const [reviewVisible, setReviewVisible] = useState(3);

  const { slug } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { static_words } = useSelector((state) => state);

  const anchorVillage = [
    {
      name: t('pages.history'),
      url: 'history',
    },
    {
      name: t('pages.etnografy'),
      url: 'etnografiya',
    },
    {
      name: t('pages.w_to_sty'),
      url: 'tostay',
    },
    {
      name: t('pages.w_to_eat'),
      url: 'eatfood',
    },
    {
      name: t('pages.w_to_go'),
      url: 'togo',
    },
    {
      name: t('pages.reviews'),
      url: 'review',
    },
    {
      name: t('pages.map'),
      url: 'map',
    },
    {
      name: t('pages.visit_too'),
      url: 'too',
    },
  ];

  const handleShow = () => {
    if (window.innerWidth > 1152) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleModalOne = () => setModalOne(!modalOne);
  const handleModalTwo = () => setModalTwo(!modalTwo);

  const showMoreHotel = () => {
    setHotelVisible((pValue) => pValue + 3);
  };
  const showMoreRestaurant = () => {
    setRestaurantVisible((pValue) => pValue + 3);
  };
  const showMoreFamous = () => {
    setFamousVisible((pValue) => pValue + 3);
  };
  const showMoreTour = () => {
    setTourVisible((pValue) => pValue + 3);
  };
  const showMoreCraft = () => {
    setCraftVisible((pValue) => pValue + 3);
  };
  const showMoreReview = () => {
    setReviewVisible((pValue) => pValue + 3);
  };

  const getVillage = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get(`api/v1/village/village/${slug}/`)
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data;
          if (data) {
            setVillage(data);
            addToRecent(data?.id);
          } else {
            setVillage({});
          }
        }
      })
      .catch((error) => setVillage({}))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleRate = (v) => {
    setRate(v);
  };

  const handlePushLink = (url) => {
    if (url) {
      navigate(`${url}`);
    }
  };

  const onSubmitReview = (ev) => {
    ev.preventDefault();
    dispatch(setLoading(true));
    let t = true,
      e = {};
    if (!obj.name) {
      t = false;
      e = { ...e, name: true };
    }
    if (!obj.username) {
      t = false;
      e = { ...e, username: true };
    }
    if (!obj.content) {
      t = false;
      e = { ...e, content: true };
    }

    const formData = new FormData();
    formData.append(
      'fullname',
      get(obj, 'username', '') + '' + get(obj, 'name', '')
    );
    formData.append('content', get(obj, 'content', ''));
    formData.append('review', rate === null ? 0 : rate);
    formData.append('village', Number(village?.id));

    if (t) {
      GetNotAuthInstance()
        .post('api/v1/village/review/', formData)
        .then((res) => {
          dispatch(setNewVillageReview(res.data));
          setVillage({
            ...village,
            reviews_avg: {
              reviews_avg:
                ((village?.reviews_avg?.reviews_avg ?? 0) *
                  (village?.reviews_avg?.reviews_count ?? 1) +
                  rate) /
                ((village?.reviews_avg?.reviews_count ?? 0) + 1),
              reviews_count: (village?.reviews_avg?.reviews_count ?? 0) + 1,
            },
            reviews: [
              {
                fullname: get(obj, 'username', '') + '' + get(obj, 'name', ''),
                content: get(obj, 'content', ''),
                review: rate,
                model: 'village',
              },
              ...(village?.reviews ?? []),
            ],
          });
          setModalOne(false);
          setModalTwo(false);
          setObj({});
          window.scroll(0, 0);
        })
        .catch((error) => {})
        .finally(() => dispatch(setLoading(false)));
    } else {
      setErr(e);
      dispatch(setLoading(false));
    }
  };

  const addToRecent = (village__id = 0) => {
    let recent = window.localStorage.getItem('recent');
    recent = JSON.parse(recent?.length ? recent : '[]');
    setRecentViewed(recent);
    if (!recent.includes(village__id)) recent = [village__id, ...recent];
    if (recent.length > 4) recent = recent.splice(0, 4);
    window.localStorage.setItem('recent', JSON.stringify(recent));
  };

  useEffect(() => {
    getVillage();
    handlePushLink();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [i18n.language, slug]);

  useEffect(() => {
    handleShow();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  window.addEventListener('resize', handleShow);

  let getStaticWordCraft = static_words?.find((e) => e?.slug === 'craft');
  let getStaticWordvillages = static_words?.find((e) => e?.slug === 'villages');
  let getStaticWordabout_village = static_words?.find(
    (e) => e?.slug === 'about_village'
  );
  let getStaticWordhistory_of_village = static_words?.find(
    (e) => e?.slug === 'history_of_village'
  );
  let getStaticWordethnography_of_village = static_words?.find(
    (e) => e?.slug === 'ethnography_of_village'
  );
  let getStaticWordtours_on_village = static_words?.find(
    (e) => e?.slug === 'tours_on_village'
  );
  let getStaticWordreviews_about_village = static_words?.find(
    (e) => e?.slug === 'reviews_about_village'
  );
  let getStaticWordRecVillagesToo = static_words?.find(
    (e) => e?.slug === 'rec_villages_too'
  );
  let getStaticWordguest_houses = static_words?.find(
    (e) => e?.slug === 'guest_houses'
  );
  let getStaticWordcanteens = static_words?.find((e) => e?.slug === 'canteens');
  let getStaticWordsightseeings = static_words?.find(
    (e) => e?.slug === 'sightseeings'
  );

  return (
    <>
      {show ? (
        <Navigation anchor={anchorVillage} />
      ) : (
        <Navigation2 anchor={anchorVillage} />
      )}
      <div
        className='container'
        style={{
          paddingTop: '81px',
        }}
      >
        <Breadcrumbs>
          <ul>
            <li>
              <Link to='/'>{t('pages.main')}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <Link to='/catalog'>{getStaticWordvillages?.title}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <span className='notactive'>{village?.name}</span>
            </li>
          </ul>
        </Breadcrumbs>
        <Section1Title
          name={village?.name}
          reviews_avg={
            village?.reviews_avg?.reviews_avg
              ? village?.reviews_avg?.reviews_avg
              : village?.reviews_avg?.reviews_avg == null
              ? 0
              : village?.reviews_avg?.reviews_avg == undefined
              ? 0
              : village?.reviews_avg?.reviews_avg == 0
              ? 0
              : null
          }
          reviews_count={village?.reviews_avg?.reviews_count}
        />
        <Section23Style>
          <Section2Slider images={village?.images} />
          <Section3About
            description={village?.description}
            handleModal={handleModalOne}
            modal={modalOne}
            setObj={setObj}
            onSubmitReview={onSubmitReview}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            info={true}
            section_title={getStaticWordabout_village?.title}
            lenghtHotels={village?.hotels?.length}
            lenghtRestaurant={village?.restaurants?.length}
            lenghtFamous={village?.famous_places?.length}
            secondBtnTitle={t('pages.give_f_back')}
            location={village?.location}
          />
        </Section23Style>
        <SectionOthers>
          <Section4History
            villagee={village?.village_history}
            titlee={getStaticWordhistory_of_village?.title}
            idd1={'history'}
          />
          <Section4History
            villagee={village?.village_ethnography}
            titlee={getStaticWordethnography_of_village?.title}
            idd1={'etnografiya'}
          />
          <Section5WhereCan
            titleWhereCan={getStaticWordguest_houses?.title}
            wherecan={village?.hotels}
            idd={'tostay'}
            place={'hotel'}
            hotelVisible={hotelVisible}
            showMoreHotel={showMoreHotel}
          />
          <Section5WhereCan
            titleWhereCan={getStaticWordcanteens?.title}
            wherecan={village?.restaurants}
            idd={'eatfood'}
            place={'restourant'}
            restaurantVisible={restaurantVisible}
            showMoreRestaurant={showMoreRestaurant}
          />
          <Section5WhereCan
            titleWhereCan={getStaticWordsightseeings?.title}
            wherecan={village?.famous_places}
            idd={'togo'}
            place={'famous'}
            famousVisible={famousVisible}
            showMoreFamous={showMoreFamous}
          />
          <Section5WhereCanTwo
            titleWhereCan={getStaticWordtours_on_village?.title}
            wherecan={village?.tours}
            slugg={village?.slug}
            place={'tour'}
            tourVisible={tourVisible}
            showMoreTour={showMoreTour}
          />
          <Section5WhereCanTwo
            titleWhereCan={getStaticWordCraft?.title}
            wherecan={village?.crafts}
            slugg={village?.slug}
            place={'craft'}
            craftVisible={craftVisible}
            showMoreCraft={showMoreCraft}
          />
          <Section6VillageReviews
            reviewss={village?.reviews}
            handleModal={handleModalTwo}
            modal={modalTwo}
            setObj={setObj}
            onSubmitReview={onSubmitReview}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            sectionTitle={getStaticWordreviews_about_village?.title}
            idd={'review'}
            reviewVisible={reviewVisible}
            showMoreReview={showMoreReview}
          />

          <Section9YandexMap
            titleSection={t('pages.map')}
            idd={'map'}
            location={village?.location}
            latitude={village?.latitude}
            longitude={village?.longitude}
          />
        </SectionOthers>
      </div>
      <Section7OtherOffers
        other={village?.other_offers}
        handlePushLink={handlePushLink}
        idd={'too'}
        visit_too={getStaticWordRecVillagesToo?.title}
      />
    </>
  );
};

export default Village;
