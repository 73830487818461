import React from 'react';
import styled from 'styled-components';
import SingleStar from '../../images/svg/singlestar.svg';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const Main = styled.div`
  width: calc(100% - 264px);
  margin-left: 24px;

  @media (max-width: 815px) and (min-width: 320px) {
    width: 100%;
    margin-left: 0;
  }

  .btnMain {
    display: flex;
    justify-content: center;
    width: 100%;
    .btn {
      font-family: 'Rubik-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      border-radius: 12px;
      display: block;
      padding: 14px;
      transition: 400ms;
      width: 264px;
      background: #ffffff;
      border: 1px solid #f34c35;
      color: #f34c35;
      margin-top: 24px;
      &:hover {
        background: #f34c35;
        color: #ffffff;
      }
    }
  }

  .reviews {
    background: #ffffff;
    border: 1px solid #e9ebee;
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 24px;
    .desc {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #001d56;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-top: 16px;
    }
    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .info_sub {
        display: flex;
        & p:nth-child(2) {
          margin-left: 8px;
        }
      }
      .name {
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #001d56;
      }
      .rate {
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #001d56;
        display: flex;
        align-items: center;
      }
      .date {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #808da6;
      }

      .rate,
      .date {
        display: flex;
        justify-content: flex-end;
      }
      .name,
      .rate {
        margin-bottom: 8px;
      }
    }
    .title {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #808da6;
    }
  }
`;
const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #001d56;
`;
const ReviewFeedack = (props) => {
  const { filtered_item, getFilteredFeedBacks, nextUrlFilteredFeedBacks } =
    props;
  const { t, i18n } = useTranslation();

  return (
    <>
      <Main>
        {filtered_item.length > 0 ? (
          <>
            {filtered_item.map((item, index) => {
              const { fullname, review, created_at, content } = item;
              return (
                <React.Fragment key={index}>
                  <div className='reviews'>
                    <div className='info'>
                      <div className='info_sub'>
                        <p className='name'>{fullname}</p>
                        <p className='rate'>
                          <img src={SingleStar} alt='' />
                          <span style={{ transform: 'translate(3px,1px)' }}>
                            {review}
                          </span>
                        </p>
                      </div>
                      <div className=''>
                        <p className='date'>
                          <Moment format='DD.MM.YYYY'>{created_at}</Moment>
                        </p>
                      </div>
                    </div>
                    {/* <p className='title'>{findVillage?.name}</p> */}
                    <p className='desc'>{content}</p>
                  </div>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <NotFound>{t('component_t.r_not_found')}</NotFound>
        )}
        <div className='btnMain'>
          {nextUrlFilteredFeedBacks ? (
            <button
              className='btn'
              onClick={() => {
                getFilteredFeedBacks(2, nextUrlFilteredFeedBacks);
              }}
            >
              {t('component_t.show_more')}
            </button>
          ) : (
            ''
          )}
        </div>
      </Main>
    </>
  );
};

export default ReviewFeedack;
