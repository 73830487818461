export const VILLAGES = 'VILLAGES';
export const VILLAGE_REVIEWS = 'VILLAGE_REVIEWS';
export const ADD_VILLAGE_REVIEWS = 'ADD_VILLAGE_REVIEWS';
export const HOTEL_REVIEWS = 'HOTEL_REVIEWS';
export const ADD_HOTEL_REVIEWS = 'ADD_HOTEL_REVIEWS';
export const RESTAURANT_REVIEWS = 'RESTAURANT_REVIEWS';
export const ADD_RESTAURANT_REVIEWS = 'ADD_RESTAURANT_REVIEWS';
export const FAMOUS_REVIEWS = 'FAMOUS_REVIEWS';
export const ADD_FAMOUS_REVIEWS = 'ADD_FAMOUS_REVIEWS';
export const TOUR_REVIEWS = 'TOUR_REVIEWS';
export const ADD_TOUR_REVIEWS = 'ADD_TOUR_REVIEWS';
export const CRAFT_REVIEWS = 'CRAFT_REVIEWS';
export const ADD_CRAFT_REVIEWS = 'ADD_CRAFT_REVIEWS';
export const CATEGORIES = 'CATEGORIES';
export const LOADING = 'LOADING';
export const SET_LAN = 'SET_LAN';
export const STATIC_WORDS = 'STATIC_WORDS';
