import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Breadcrumbs } from '../../components/GlobalStyledComponents';
import {
  Navigation,
  Section1Title,
  Section2Slider,
  Section3About,
  Section5WhereCan,
  Section6VillageReviews,
  Section7OtherOffers,
  Section8ImportantInfo,
  Section9YandexMap,
} from '../../components/VillageSingle';
import Navigation2 from '../../components/VillageSingle/Navigation2';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { setLoading, setNewHotelReview, setHotelReviews } from '../../redux';

const Section23Style = styled.div`
  display: flex;
  margin-top: 18px;

  @media (max-width: 870px) and (min-width: 320px) {
    flex-direction: column;
  }
  & > div {
    &:nth-child(1) {
      width: calc(100% - 359px);
      margin-right: 24px;
      @media (max-width: 870px) and (min-width: 320px) {
        margin-right: 0;
        margin-bottom: 24px;
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 359px;
      @media (max-width: 870px) and (min-width: 320px) {
        width: 100%;
      }
    }
  }
`;
const SectionOthers = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    width: calc(100% - 359px);
    margin-right: 24px;
    @media (max-width: 920px) and (min-width: 320px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const Hotel = () => {
  const [show, setShow] = useState(false);
  const [hotel, setHotel] = useState({});
  const [modalHotel, setModalHotel] = useState(false);
  const [modalThree, setModalThree] = useState(false);
  const [rate, setRate] = useState(0);
  const [obj, setObj] = useState({});
  const [err, setErr] = useState({});

  const [restaurantVisible, setRestaurantVisible] = useState(3);
  const [reviewVisible, setReviewVisible] = useState(3);

  const { static_words } = useSelector((state) => state);

  const { slug } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const anchorHotel = [
    {
      name: t('pages.info'),
      url: 'info',
    },
    {
      name: t('pages.reviews'),
      url: 'review',
    },
    {
      name: t('pages.restorans'),
      url: 'restorans',
    },
    {
      name: t('pages.map'),
      url: 'map',
    },
    {
      name: t('pages.others'),
      url: 'like',
    },
  ];

  const handleModalHotel = () => setModalHotel(!modalHotel);
  const handleModalThree = () => setModalThree(!modalThree);

  const handleShow = () => {
    if (window.innerWidth > 1152) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const showMoreRestaurant = () => {
    setRestaurantVisible((pValue) => pValue + 3);
  };
  const showMoreReview = () => {
    setReviewVisible((pValue) => pValue + 3);
  };

  const getHotel = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get(`api/v1/hotel/hotel/${slug}/`)
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data;
          if (data) {
            setHotel(data);
          } else {
            setHotel({});
          }
        }
      })
      .catch((error) => setHotel({}))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleRate = (v) => {
    setRate(v);
  };

  const handlePushLink = (url) => {
    if (url) {
      navigate(`${url}`);
    }
  };

  const handleChanglePhone = (e) => {
    setObj({ ...obj, phone: e });
  };

  const onSubmitRequest = (ev) => {
    ev.preventDefault();
    dispatch(setLoading(true));
    let t = true,
      e = {};
    if (!obj.name) {
      t = false;
      e = { ...e, name: true };
    }
    if (!obj.username) {
      t = false;
      e = { ...e, username: true };
    }
    if (!obj.email) {
      t = false;
      e = { ...e, email: true };
    }
    if (!obj.comment) {
      t = false;
      e = { ...e, content: true };
    }

    const formData = new FormData();
    formData.append(
      'fullname',
      get(obj, 'username', '') + '' + get(obj, 'name', '')
    );
    formData.append('email', get(obj, 'email', ''));
    formData.append('phone', get(obj, 'phone', ''));
    formData.append('comment', get(obj, 'comment', ''));
    formData.append('hotel', Number(hotel?.id));

    if (t) {
      GetNotAuthInstance()
        .post('api/v1/hotel/user-application/', formData)
        .then((res) => {
          setModalThree(false);
          setObj({});
          window.scroll(0, 0);
        })
        .catch((error) => {})
        .finally(() => dispatch(setLoading(false)));
    } else {
      setErr(e);
      dispatch(setLoading(false));
    }
  };

  const onSubmitReview = (ev) => {
    ev.preventDefault();
    dispatch(setLoading(true));
    let t = true,
      e = {};
    if (!obj.name) {
      t = false;
      e = { ...e, name: true };
    }
    if (!obj.username) {
      t = false;
      e = { ...e, username: true };
    }
    if (!obj.content) {
      t = false;
      e = { ...e, content: true };
    }

    const formData = new FormData();
    formData.append(
      'fullname',
      get(obj, 'username', '') + '' + get(obj, 'name', '')
    );
    formData.append('content', get(obj, 'content', ''));
    formData.append('review', rate === null ? 0 : rate);
    formData.append('hotel', Number(hotel?.id));
    if (t) {
      GetNotAuthInstance()
        .post('api/v1/hotel/review/', formData)
        .then((res) => {
          dispatch(setNewHotelReview(res?.data));
          setHotel({
            ...hotel,
            reviews_avg: {
              reviews_avg:
                ((hotel?.reviews_avg?.reviews_avg ?? 0) *
                  (hotel?.reviews_avg?.reviews_count ?? 1) +
                  rate) /
                ((hotel?.reviews_avg?.reviews_count ?? 0) + 1),
              reviews_count: (hotel?.reviews_avg?.reviews_count ?? 0) + 1,
            },
            reviews: [
              {
                fullname: get(obj, 'username', '') + '' + get(obj, 'name', ''),
                content: get(obj, 'content', ''),
                review: rate,
                model: 'hotel',
              },
              ...(hotel?.reviews ?? []),
            ],
          });
          setModalHotel(false);
          setObj({});
          window.scroll(0, 0);
        })
        .catch((error) => {})
        .finally(() => dispatch(setLoading(false)));
    } else {
      setErr(e);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getHotel();
    handlePushLink();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [i18n.language, slug]);

  useEffect(() => {
    handleShow();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  window.addEventListener('resize', handleShow);

  let getStaticWordvillages = static_words?.find((e) => e?.slug === 'villages');
  let getStaticWordabout_guest_house = static_words?.find(
    (e) => e?.slug === 'about_guest_house'
  );
  let getStaticWordreviews_about_guest_house = static_words?.find(
    (e) => e?.slug === 'reviews_about_guest_house'
  );
  let getStaticWordrec_guest_houses_too = static_words?.find(
    (e) => e?.slug === 'rec_guest_houses_too'
  );
  let getStaticWordcanteens_nearby = static_words?.find(
    (e) => e?.slug === 'canteens_nearby'
  );

  return (
    <>
      {show ? (
        <Navigation anchor={anchorHotel} />
      ) : (
        <Navigation2 anchor={anchorHotel} />
      )}
      <div
        className='container'
        style={{
          paddingTop: '81px',
        }}
      >
        <Breadcrumbs>
          <ul>
            <li>
              <Link to='/'>{t('pages.main')}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <Link to='/catalog'>{getStaticWordvillages?.title}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <Link to={`/catalog/${hotel?.village?.slug}`}>
                {hotel?.village?.name}
              </Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <span className='notactive'>{hotel?.name}</span>
            </li>
          </ul>
        </Breadcrumbs>
        <Section1Title
          name={hotel?.name}
          reviews_avg={
            hotel?.reviews_avg?.reviews_avg
              ? hotel?.reviews_avg?.reviews_avg
              : hotel?.reviews_avg?.reviews_avg == undefined
              ? 0
              : hotel?.reviews_avg?.reviews_avg == 0
              ? 0
              : null
          }
          reviews_count={hotel?.reviews_avg?.reviews_count}
        />
        <Section23Style>
          <Section2Slider images={hotel?.images} />
          <Section3About
            description={hotel?.description}
            handleModal3={handleModalThree}
            modal3={modalThree}
            setObj={setObj}
            onSubmitRequest={onSubmitRequest}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            info={null}
            section_title={getStaticWordabout_guest_house?.title}
            secondBtnTitle={t('pages.leav_request')}
            handleChanglePhone={handleChanglePhone}
            location={hotel?.location}
          />
        </Section23Style>
        <SectionOthers>
          <Section8ImportantInfo
            address={hotel?.address}
            phone={hotel?.phone}
            titleSection={t('pages.important_info')}
            idd={'info'}
          />
          <Section6VillageReviews
            reviewss={hotel?.reviews}
            handleModal={handleModalHotel}
            modal={modalHotel}
            setObj={setObj}
            onSubmitReview={onSubmitReview}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            sectionTitle={getStaticWordreviews_about_guest_house?.title}
            idd={'review'}
            reviewVisible={reviewVisible}
            showMoreReview={showMoreReview}
          />
          <Section5WhereCan
            titleWhereCan={getStaticWordcanteens_nearby?.title}
            wherecan={hotel?.nearby_restaurants}
            idd={'restorans'}
            place={'restourant'}
            restaurantVisible={restaurantVisible}
            showMoreRestaurant={showMoreRestaurant}
          />
          <Section9YandexMap
            titleSection={t('pages.map')}
            idd={'map'}
            location={hotel?.location}
            latitude={hotel?.latitude}
            longitude={hotel?.longitude}
          />
        </SectionOthers>
        <Section7OtherOffers
          other={hotel?.other_offers}
          handlePushLink={handlePushLink}
          idd={'like'}
          place={'hotel'}
          visit_too={getStaticWordrec_guest_houses_too?.title}
        />
      </div>
    </>
  );
};

export default Hotel;
