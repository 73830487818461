import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { getLanguage } from '../../helpers/language';
import Brand_en_1 from '../../images/svg/brand_en_1.svg';
import Brand_en_2 from '../../images/svg/brand_en_2.svg';
import Brand_ru_1 from '../../images/svg/brand_ru_1.svg';
import Brand_ru_2 from '../../images/svg/brand_ru_2.svg';
import Brand_uz_1 from '../../images/svg/brand_uz_1.svg';
import Brand_uz_2 from '../../images/svg/brand_uz_2.svg';
import Menu from '../../images/svg/menu.svg';
import { setLan } from '../../redux';

const Main = styled.section`
  position: fixed;
  width: 100%;
  height: 66px;
  background: #fff;
  border-bottom: 1px solid #fafafa;
  z-index: 995;
  overflow: hidden;
  .nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    transition: 300ms;
    .left {
      img {
        padding: 22px 0;
      }
    }
    .brand_link1 {
      display: flex;
      width: 152px;
      text-align: center;
      img {
        padding: 0;
      }
    }
    .brand_link2 {
      display: flex;
      width: 201px;
      text-align: center;
      img {
        padding: 0;
      }
    }
    .brand_link3 {
      display: flex;
      width: 162px;
      text-align: center;
      img {
        padding: 0;
      }
    }
    .right {
      display: flex;
      align-items: center;
      ul {
        display: flex;
        list-style-type: none;
        li {
          margin-right: 24px;
          &:nth-last-child(1) {
            margin-right: 0;
          }
          a {
            font-family: 'Rubik-Regular', sans-serif;
            font-size: 14px;
            color: #001d56;
            transition: 200ms;
            &:hover {
              color: #f34c35;
            }
            p {
              padding: 23px 0;
            }
          }
        }
      }
      .language_p {
        position: relative;
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 14px;
        color: #001d56;
        transition: 200ms;
        margin-left: 24px;
        .t1 {
          cursor: pointer;
          padding: 23px 0;
          &:hover {
            color: #f34c35;
          }
        }
        .content_layer {
          position: absolute;
        }
        .content {
          position: absolute;
          bottom: -90px;
          left: -53px;
          width: 70px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
            0 3px 6px rgba(0, 0, 0, 0.23);
          border-radius: 6px;
          z-index: 996;
          .t2 {
            cursor: pointer;
            padding: 6px 0;
            width: 100%;
            text-align: center;
            &:nth-child(1) {
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
            }
            &:nth-last-child(1) {
              border-bottom-left-radius: 6px;
              border-bottom-right-radius: 6px;
            }
            &:hover {
              color: #fff;
              background: #001d56;
            }
          }
        }
      }
    }
  }
  .nav2 {
    margin-top: 6px;
    ul {
      display: flex;
      justify-content: flex-start;
      list-style-type: none;
      li {
        margin-right: 24px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        a {
          font-family: 'Rubik-Regular', sans-serif;
          font-size: 14px;
          color: #001d56;
          transition: 200ms;
          &:hover {
            color: #f34c35;
          }
          p {
            padding: 23px 0;
          }
        }
      }
    }
  }
  .nav_active {
    margin-top: -66px;
  }
  .nav2_active {
    margin-top: 6px;
  }
  .nav_1 {
    margin-top: 0;
  }
  .nav_2 {
    margin-top: 0;
  }
`;

const MobileMenu = styled.div`
  .card,
  .card_active {
    position: fixed;
    bottom: 0;
    width: 300px;
    height: calc(100% - 67px);
    background: #fff;
    border-left: 1px solid #fafafa;
    z-index: 991;
    transition: 300ms;
    overflow-y: auto;
  }
  .card {
    right: -300px;
  }
  .card_default {
    ul {
      li {
        a {
          text-align: right;
          font-family: 'Rubik-Medium', sans-serif;
          font-size: 18px;
          color: #001d56;
          transition: 200ms;
          &:hover {
            color: #f34c35;
          }
          p {
            padding: 10px;
          }
        }
      }
    }
  }
  .card_active {
    right: 0;
  }
`;

const Layer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: calc(100% - 67px);
  width: 100%;
  background: rgba(0, 29, 86, 0.5);
  z-index: 990;
`;

const Test = styled.div`
  position: fixed;
  z-index: 996;
  top: 46px;
  width: 100%;
  font-family: 'Rubik-Regular', sans-serif;
  font-size: 14px;
  color: #001d56;
  transition: 200ms;
  margin-top: 0;
  .content_layer {
    position: fixed;
  }
  .content_main {
    display: flex;
    justify-content: flex-end;
  }
  .content {
    /* position: fixed;
    overflow: hidden;
    bottom: -90px;
    right: 53px; */
    width: 70px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 6px;
    z-index: 999;
    .t2 {
      cursor: pointer;
      padding: 6px 0;
      width: 100%;
      text-align: center;
      &:nth-child(1) {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:nth-last-child(1) {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      &:hover {
        color: #fff;
        background: #001d56;
      }
    }
  }
  /* @media only screen and (max-width: 1152px) and (min-width: 320px) {
    display: none !important;
  } */
`;

const Navigation = ({ anchor }) => {
  const [show, setShow] = useState(false);
  const [showM, setShowM] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showLanguageP, setShowLanguageP] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const lan = getLanguage();
  const { static_words } = useSelector((state) => state);

  const handleShowLanguage = () => setShowLanguageP(!showLanguageP);

  const onLanguageHandle = (newLang) => {
    i18n.changeLanguage(newLang);
    dispatch(setLan(newLang));
    window.localStorage.setItem('language', newLang);
    setShowLanguageP(false);
  };

  const handleShowModal = () => setShowMenu(!showMenu);

  const handleShow = () => {
    if (window.innerWidth > 1152) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleShow2 = () => {
    let nav = document.querySelector('.nav');
    let nav2 = document.querySelector('.nav2');

    if (window.scrollY >= 1) {
      setShowM(false);
    } else {
      setShowM(true);
    }
    if (window.scrollY >= 66) {
      nav.classList.add('nav_active');
      nav2.classList.add('nav2_active');
    } else {
      nav.classList.remove('nav_active');
      nav2.classList.remove('nav2_active');
    }
  };

  useEffect(() => {
    handleShow();
    // const pathname = window.location.pathname;
    // if (pathname === '/') {
    // }
    handleShow2();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const OverFlowHidden = createGlobalStyle`
   body{
      overflow: hidden;
   }
  `;

  let getStaticWordCraft = static_words?.find((e) => e?.slug === 'craft');

  window.addEventListener('resize', handleShow);
  window.addEventListener('scroll', handleShow2);

  return (
    <>
      <Main>
        <div className='container'>
          <div className={show ? `nav ` : `nav nav_1`}>
            <div className='left'>
              {lan === 'uz' ? (
                <div className='brand_link3'>
                  <Link to='/'>
                    <img src={Brand_uz_1} alt='' />
                    <img src={Brand_uz_2} alt='' />
                  </Link>
                </div>
              ) : lan === 'ru' ? (
                <div className='brand_link2'>
                  <Link to='/'>
                    <img src={Brand_ru_2} alt='' />
                    <img src={Brand_ru_1} alt='' />
                  </Link>
                </div>
              ) : (
                <div className='brand_link1'>
                  <Link to='/'>
                    <img src={Brand_en_1} alt='' />
                    <img src={Brand_en_2} alt='' />
                  </Link>
                </div>
              )}
            </div>

            <div className='right'>
              {show ? (
                <ul>
                  <li>
                    <Link to='/catalog'>
                      <p>{t('navbar.catalog')}</p>
                    </Link>
                  </li>
                  <li>
                    <Link to='/route-filter'>
                      <p>{t('navbar.route')}</p>
                    </Link>
                  </li>
                  <li>
                    <a href='/#categoryy'>
                      <p>{getStaticWordCraft?.title}</p>
                    </a>
                  </li>
                </ul>
              ) : null}
              <p className='language_p' onClick={handleShowLanguage}>
                <span className='t1'>
                  {lan === 'uz' ? (
                    <>{t('navbar.uz')}</>
                  ) : lan === 'ru' ? (
                    <>{t('navbar.ru')}</>
                  ) : (
                    <>{t('navbar.en')}</>
                  )}
                </span>
              </p>
              {!show ? (
                <img
                  src={Menu}
                  className='cursor'
                  onClick={handleShowModal}
                  style={{ marginLeft: '24px', padding: '23px 0' }}
                  alt=''
                />
              ) : null}
            </div>
          </div>
          <div className={show ? `nav2 ` : `nav2 nav_2`}>
            <ul>
              {anchor?.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={`#${item?.url}`}>
                      <p>{item?.name}</p>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Main>
      {show ? null : (
        <>
          <MobileMenu>
            <div
              className={
                showMenu ? 'card_active card_default' : 'card_default card'
              }
            >
              <ul>
                {anchor?.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={`#${item?.url}`} onClick={handleShowModal}>
                        <p>{item?.name}</p>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </MobileMenu>
          {showMenu ? <OverFlowHidden /> : null}
          {showMenu ? <Layer onClick={handleShowModal} /> : null}
        </>
      )}
      {!showM ? null : (
        <Test>
          <div className='container'>
            {showLanguageP ? (
              <div className='content_main'>
                <span className='content'>
                  <span className='t2' onClick={() => onLanguageHandle('uz')}>
                    {t('navbar.uz')}
                  </span>
                  <span className='t2' onClick={() => onLanguageHandle('ru')}>
                    {t('navbar.ru')}
                  </span>
                  <span className='t2' onClick={() => onLanguageHandle('en')}>
                    {t('navbar.en')}
                  </span>
                </span>
                <span className='content_layer' />
              </div>
            ) : null}
          </div>
        </Test>
      )}
    </>
  );
};

export default Navigation;
