import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  FourColumnCard,
  FourColumnContainer,
} from "../components/GlobalStyledComponents";
import { GetNotAuthInstance } from "../helpers/httpClient";
import LogoWhite from "../images/svg/logo_white.svg";
import { setLoading, setVillages } from "../redux";
import Brand_en_1 from "../images/svg/brand_en_1.svg";
import Brand_en_2 from "../images/svg/brand_en_2 white.svg";
import Brand_ru_1 from "../images/svg/brand_ru_1.svg";
import Brand_ru_2 from "../images/svg/brand_ru_2 white.svg";
import Brand_uz_1 from "../images/svg/brand_uz_1.svg";
import Brand_uz_2 from "../images/svg/brand_uz_2 white.svg";
import NazirovBrandWhite from "../images/nazirov light.png";
import { getLanguage } from "../helpers/language";

const Main = styled.div`
  background: #001d56;
  margin-top: 72px;
  .footer {
    .brand_link1 {
      display: flex;
      width: 152px;
      text-align: center;
      padding-top: 32px;
      img {
        padding: 0;
      }
    }
    .brand_link2 {
      display: flex;
      width: 201px;
      text-align: center;
      padding-top: 32px;
      img {
        padding: 0;
      }
    }
    .brand_link3 {
      display: flex;
      width: 162px;
      text-align: center;
      padding-top: 32px;
      img {
        padding: 0;
      }
    }
    .title {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #808da6;
      margin-bottom: 5px;
    }
    .sub_title {
      display: block;
      font-family: "Rubik-Regular", sans-serif;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
      margin-bottom: 8px;
      transition: 200ms;
      &:hover {
        color: #808da6;
      }
      &:nth-last-child(1) {
        margin-bottom: 7px;
      }
    }
  }
  .footer_sub {
    border-top: 1px solid #1e386b;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-family: "Rubik-Regular", sans-serif;
      font-size: 12px;
      line-height: 14px;
      color: #808da6;
    }
    span {
      margin-right: 8px;
    }
    img {
      width: 100px;
    }
    @media only screen and (max-width: 765px) and (min-width: 320px) {
      flex-direction: column;
      p {
        &:nth-child(2) {
          margin-top: 15px;
        }
      }
    }
  }
`;
const Footer = () => {
  const [hotelsList, setHotelsList] = useState([]);
  const [restoransList, setRestoransList] = useState([]);
  const { villages, static_words } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lan = getLanguage();

  const getVillages = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/village/village/?page=1&page_size=8")
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            dispatch(setVillages(result));
          } else {
            dispatch(setVillages([]));
          }
        }
      })
      .catch((error) => {})
      .finally(() => dispatch(setLoading(false)));
  };

  const getHotels = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/hotel/hotel/?page=1&page_size=4")
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            setHotelsList(result);
          } else {
            setHotelsList([]);
          }
        }
      })
      .catch((error) => {})
      .finally(() => dispatch(setLoading(false)));
  };

  const getRestorans = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/restaurant/restaurant/?page=1&page_size=4")
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            setRestoransList(result);
          } else {
            setRestoransList([]);
          }
        }
      })
      .catch((error) => {})
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    getHotels();
    getRestorans();
    getVillages();
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [i18n.language]);

  let getStaticWordCraft = static_words?.find((e) => e?.slug === "craft");
  let getStaticWordvillages = static_words?.find((e) => e?.slug === "villages");
  let getStaticWordguest_houses = static_words?.find(
    (e) => e?.slug === "guest_houses"
  );
  let getStaticWordcanteens = static_words?.find((e) => e?.slug === "canteens");

  return (
    <>
      <Main>
        <div className="footer">
          <div className="container">
            {lan === "uz" ? (
              <div className="brand_link3">
                <Link to="/">
                  <img src={Brand_uz_1} alt="" />
                  <img src={Brand_uz_2} alt="" />
                </Link>
              </div>
            ) : lan === "ru" ? (
              <div className="brand_link2">
                <Link to="/">
                  <img src={Brand_ru_2} alt="" />
                  <img src={Brand_ru_1} alt="" />
                </Link>
              </div>
            ) : (
              <div className="brand_link1">
                <Link to="/">
                  <img src={Brand_en_1} alt="" />
                  <img src={Brand_en_2} alt="" />
                </Link>
              </div>
            )}
          </div>
          <FourColumnContainer>
            <FourColumnCard>
              <p className="title">{t("layout.navigation")}</p>
              <Link to="/catalog" className="sub_title">
                {t("layout.catalog")}
              </Link>
              <a href="/#categoryy" className="sub_title">
                <p>{getStaticWordCraft?.title}</p>
              </a>
            </FourColumnCard>
            <FourColumnCard>
              <p className="title">{getStaticWordvillages?.title}</p>
              {villages?.map((village, index) => {
                return (
                  <Link
                    to={`/catalog/${village?.slug}`}
                    key={index}
                    className="sub_title"
                  >
                    {village?.name}
                  </Link>
                );
              })}
            </FourColumnCard>
            <FourColumnCard>
              <p className="title">{getStaticWordguest_houses?.title}</p>
              {hotelsList?.map((hotel, index) => {
                return (
                  <Link
                    to={`/catalog/${hotel?.village?.slug}/hotel/${hotel?.slug}`}
                    key={index}
                    className="sub_title"
                  >
                    {hotel?.name}
                  </Link>
                );
              })}
            </FourColumnCard>
            <FourColumnCard>
              <p className="title">{getStaticWordcanteens?.title}</p>
              {restoransList?.map((restoran, index) => {
                return (
                  <Link
                    to={`/catalog/${restoran?.village?.slug}/restourant/${restoran?.slug}`}
                    key={index}
                    className="sub_title"
                  >
                    {restoran?.name}
                  </Link>
                );
              })}
            </FourColumnCard>
          </FourColumnContainer>
        </div>
        <div className="footer_sub container">
          <p>© 2022 Tourvillages. {t("layout.all_rights_reserved")}</p>
          <p style={{ display: "flex", alignItems: "center" }}>
            <span>developed by </span>
            <a href="https://nazirov.co/" target="_blank">
              <img src={NazirovBrandWhite} alt="" />
            </a>
          </p>
        </div>
      </Main>
    </>
  );
};

export default Footer;
