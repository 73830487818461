import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import styled from 'styled-components';
import AppCardImg from '.././images/appcardimg.png';

const Main = styled.div`
  position: relative;
  border-radius: 20px;
  transition: 400ms;
  &:hover {
    background: #fafafa;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  img {
    transition: 400ms;
    width: 100%;
    height: 191px;
    object-fit: cover;
    border-radius: 20px;
  }
  .text {
    margin-left: 12px;
    margin-top: 16px;
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #001d56;
  }
  .text2 {
    padding: 0 12px;
    margin-top: 4px;
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #808da6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .bottom {
    background: #fafafa;
    border-radius: 12px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    .t1 {
      margin-top: 16px;
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 16px;
      color: #001d56;
      margin: 0;
      margin-left: 5.66px;
      transform: translate(0, -1px);
    }
    .t2 {
      margin-top: 4px;
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      color: #808da6;
      margin: 0;
    }
  }
`;
const AppCardPopularHandicrafts = ({ item }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/catalog/${item?.village?.slug}/famous/${item?.slug}`}>
      <Main>
        <img src={item?.image} alt='' />
        <p className='text'>{item?.name}</p>
        <p className='text2'>{item?.description}</p>
        <div className='bottom'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StarRatings
              rating={
                item?.reviews_avg?.reviews_avg
                  ? item?.reviews_avg?.reviews_avg
                  : item?.reviews_avg?.reviews_avg == 0
                  ? 0
                  : 0
              }
              starRatedColor='#F39035'
              starDimension='16px'
              starSpacing='.2px'
            />
            <p className='t1'>{item?.reviews_avg?.reviews_avg}</p>
          </div>
          <p className='t2'>
            {item?.reviews_avg?.reviews_count} {t('component_t.review_count')}
          </p>
        </div>
      </Main>
    </Link>
  );
};

export default AppCardPopularHandicrafts;
