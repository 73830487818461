import React from 'react';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import styled from 'styled-components';

const Main = styled.div`
  .t1 {
    font-family: 'Rubik-Bold', sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #001d56;
    span {
      color: #f34c35;
    }
  }
  .t2 {
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #808da6;
    &:nth-child(2) {
      margin-left: 14px;
      margin-right: 8px;
    }
  }
  .t3 {
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #001d56;
    margin-left: 3px;
  }
  .info {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const Section1Title = ({ name, reviews_avg, reviews_count }) => {
  const { t, i18n } = useTranslation();

  return (
    <Main>
      <p className='t1'>{name}</p>
      <div className='info'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StarRatings
            rating={reviews_avg}
            starRatedColor='#F39035'
            starDimension='16px'
            starSpacing='.2px'
          />
          <p className='t3'>{reviews_avg}</p>
        </div>
        <p className='t2'>
          {reviews_count} {t('component_t.r_ws')}
        </p>
        {/* <p className='t2'>10 рукоделий</p> */}
      </div>
    </Main>
  );
};

export default Section1Title;
