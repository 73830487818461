import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Breadcrumbs } from '../../components/GlobalStyledComponents';
import {
  Navigation,
  Section1Title,
  Section2Slider,
  Section3About,
  Section5WhereCan,
  Section6VillageReviews,
  Section7OtherOffers,
  Section8ImportantInfo,
  Section9YandexMap,
} from '../../components/VillageSingle';
import Navigation2 from '../../components/VillageSingle/Navigation2';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { setLoading, setNewCraftReview, setCraftReviews } from '../../redux';

const Section23Style = styled.div`
  display: flex;
  margin-top: 18px;

  @media (max-width: 870px) and (min-width: 320px) {
    flex-direction: column;
  }
  & > div {
    &:nth-child(1) {
      width: calc(100% - 359px);
      margin-right: 24px;
      @media (max-width: 870px) and (min-width: 320px) {
        margin-right: 0;
        margin-bottom: 24px;
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 359px;
      @media (max-width: 870px) and (min-width: 320px) {
        width: 100%;
      }
    }
  }
`;
const SectionOthers = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    width: calc(100% - 359px);
    margin-right: 24px;
    @media (max-width: 920px) and (min-width: 320px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const Craft = () => {
  const [show, setShow] = useState(false);
  const [craft, setCraft] = useState({});
  const [modalOne, setModalOne] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const [modalThree, setModalThree] = useState(false);
  const [rate, setRate] = useState(0);
  const [obj, setObj] = useState({});
  const [err, setErr] = useState({});
  const [reviewVisible, setReviewVisible] = useState(3);

  const { slug } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { static_words } = useSelector((state) => state);

  const anchorHotel = [
    {
      name: t('pages.reviews'),
      url: 'review',
    },
    {
      name: t('pages.map'),
      url: 'map',
    },
    {
      name: t('pages.others'),
      url: 'like',
    },
  ];

  const handleModalOne = () => setModalOne(!modalOne);
  const handleModalTwo = () => setModalTwo(!modalTwo);
  const handleModalThree = () => setModalThree(!modalThree);

  const handleShow = () => {
    if (window.innerWidth > 1152) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const showMoreReview = () => {
    setReviewVisible((pValue) => pValue + 3);
  };

  const getCraft = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get(`api/v1/village/craft/${slug}/`)
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data;
          if (data) {
            setCraft(data);
          } else {
            setCraft({});
          }
        }
      })
      .catch((error) => setCraft({}))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleRate = (v) => {
    setRate(v);
  };

  const handlePushLink = (url) => {
    if (url) {
      navigate(`${url}`);
    }
  };

  const onSubmitReview = (ev) => {
    ev.preventDefault();
    dispatch(setLoading(true));
    let t = true,
      e = {};
    if (!obj.name) {
      t = false;
      e = { ...e, name: true };
    }
    if (!obj.username) {
      t = false;
      e = { ...e, username: true };
    }
    if (!obj.content) {
      t = false;
      e = { ...e, content: true };
    }

    const formData = new FormData();
    formData.append(
      'fullname',
      get(obj, 'username', '') + '' + get(obj, 'name', '')
    );
    formData.append('content', get(obj, 'content', ''));
    formData.append('review', rate === null ? 0 : rate);
    formData.append('craft', Number(craft?.id));

    if (t) {
      GetNotAuthInstance()
        .post('api/v1/village/craft-review/', formData)
        .then((res) => {
          dispatch(setNewCraftReview(res.data));
          setCraft({
            ...craft,
            reviews_avg: {
              reviews_avg:
                ((craft?.reviews_avg?.reviews_avg ?? 0) *
                  (craft?.reviews_avg?.reviews_count ?? 1) +
                  rate) /
                ((craft?.reviews_avg?.reviews_count ?? 0) + 1),
              reviews_count: (craft?.reviews_avg?.reviews_count ?? 0) + 1,
            },
            reviews: [
              {
                fullname: get(obj, 'username', '') + '' + get(obj, 'name', ''),
                content: get(obj, 'content', ''),
                review: rate,
                model: 'craft',
              },
              ...(craft?.reviews ?? []),
            ],
          });
          setModalOne(false);
          setModalTwo(false);
          setObj({});
          window.scroll(0, 0);
        })
        .catch((error) => {})
        .finally(() => dispatch(setLoading(false)));
    } else {
      setErr(e);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getCraft();
    handlePushLink();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [i18n.language, slug]);

  useEffect(() => {
    handleShow();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  window.addEventListener('resize', handleShow);

  let getStaticWAboutCraft = static_words?.find(
    (e) => e?.slug === 'about_craft'
  );
  let getStaticWReviewsAboutCraft = static_words?.find(
    (e) => e?.slug === 'reviews_about_craft'
  );
  let getStaticWRecCraftsToo = static_words?.find(
    (e) => e?.slug === 'rec_crafts_too'
  );

  return (
    <>
      {show ? (
        <Navigation anchor={anchorHotel} />
      ) : (
        <Navigation2 anchor={anchorHotel} />
      )}
      <div
        className='container'
        style={{
          paddingTop: '81px',
        }}
      >
        <Breadcrumbs>
          <ul>
            <li>
              <Link to='/'>{t('pages.main')}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <Link to='/catalog'>{t('pages.villages')}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <Link to={`/catalog/${craft?.village?.slug}`}>
                {craft?.village?.name}
              </Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <span className='notactive'>{craft?.name}</span>
            </li>
          </ul>
        </Breadcrumbs>
        <Section1Title
          name={craft?.name}
          reviews_avg={
            craft?.reviews_avg?.reviews_avg
              ? craft?.reviews_avg?.reviews_avg
              : craft?.reviews_avg?.reviews_avg == null
              ? 0
              : craft?.reviews_avg?.reviews_avg == undefined
              ? 0
              : craft?.reviews_avg?.reviews_avg == 0
              ? 0
              : null
          }
          reviews_count={craft?.reviews_avg?.reviews_count}
        />
        <Section23Style>
          <Section2Slider images={craft?.images} />
          <Section3About
            description={craft?.description}
            handleModal={handleModalOne}
            modal={modalOne}
            setObj={setObj}
            onSubmitReview={onSubmitReview}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            info={true}
            section_title={getStaticWAboutCraft?.title}
            lenghtHotels={craft?.hotels?.length}
            lenghtRestaurant={craft?.restaurants?.length}
            secondBtnTitle={t('pages.give_f_back')}
            location={craft?.location}
          />
        </Section23Style>
        <SectionOthers>
          <Section6VillageReviews
            reviewss={craft?.reviews}
            handleModal={handleModalTwo}
            modal={modalTwo}
            setObj={setObj}
            onSubmitReview={onSubmitReview}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            sectionTitle={getStaticWReviewsAboutCraft?.title}
            idd={'review'}
            reviewVisible={reviewVisible}
            showMoreReview={showMoreReview}
          />
          <Section9YandexMap
            titleSection={t('pages.map')}
            idd={'map'}
            location={craft?.location}
            latitude={craft?.latitude}
            longitude={craft?.longitude}
          />
        </SectionOthers>
        <Section7OtherOffers
          other={craft?.other_offers}
          handlePushLink={handlePushLink}
          idd={'like'}
          place={'craft'}
          visit_too={getStaticWRecCraftsToo?.title}
        />
      </div>
    </>
  );
};

export default Craft;
