import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { setLoading, setVillages } from '../../redux';
import AppCard from '../AppCard';
import {
  AppTitleLeft,
  FourColumnCard,
  FourColumnContainer,
} from '../GlobalStyledComponents';

const Main = styled.div`
  .aBtnMain {
    width: 100%;
    display: flex;
    justify-content: center;
    .aBtn {
      margin-top: 24px;
      margin-bottom: 24px;
      background: #f34c35;
      border-radius: 8px;
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 14px;
      color: #ffffff;
      padding: 9px 14px;
      width: 330px;
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      @media only screen and (max-width: 576px) and (min-width: 320px) {
        width: 260px;
      }
    }
  }
`;

const Villages = () => {
  const { t, i18n } = useTranslation();

  const { villages, static_words } = useSelector((state) => state);

  let getStaticWordvillages = static_words?.find((e) => e?.slug === 'villages');

  return (
    <>
      <Main>
        <AppTitleLeft>
          <div className='container'>
            <p>{getStaticWordvillages?.title}</p>
          </div>
        </AppTitleLeft>
        <FourColumnContainer>
          {villages?.map((item, index) => {
            return (
              <FourColumnCard key={index}>
                <AppCard item={item} />
              </FourColumnCard>
            );
          })}
        </FourColumnContainer>
        <div className='aBtnMain' style={{ textAlign: 'center' }}>
          <Link to='/catalog' className='aBtn'>
            {t('component_t.go_to_catalog')}
          </Link>
        </div>
      </Main>
    </>
  );
};

export default Villages;
