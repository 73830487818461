import styled from 'styled-components';

export const AppTitleLeft = styled.div`
  margin-top: 48px;
  p {
    font-family: 'Rubik-Bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #001d56;
  }
`;
export const Breadcrumbs = styled.div`
  margin-bottom: 24px;
  ul {
    list-style-type: none;
    li {
      display: inline-block;
      margin-right: 5px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
      a,
      .notactive {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 14px;
        color: #808da6;
      }
    }
  }
`;

export const FourColumnContainer = styled.div`
  max-width: 1158px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const FourColumnCard = styled.div`
  position: relative;
  margin: 15px;
  width: calc((100% / 4) - 30px);

  @media (max-width: 1200px) and (min-width: 992px) {
    width: calc((100% / 3) - 30px);
  }
  @media (max-width: 992px) and (min-width: 768px) {
    width: calc((100% / 3) - 30px);
  }
  @media (max-width: 768px) and (min-width: 576px) {
    width: calc((100% / 2) - 30px);
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }
`;

export const ThreeColumnContainer = styled.div`
  max-width: 1158px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const ThreeColumnCard = styled.div`
  position: relative;
  margin: 15px;
  width: calc((100% / 3) - 30px);

  @media (max-width: 1200px) and (min-width: 992px) {
    width: calc((100% / 2) - 30px);
  }
  @media (max-width: 992px) and (min-width: 768px) {
    width: calc((100% / 2) - 30px);
  }
  @media (max-width: 768px) and (min-width: 576px) {
    width: calc((100% / 2) - 30px);
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }
`;

export const TwoColumnContainer = styled.div`
  max-width: 1158px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
`;

export const TwoColumnCard = styled.div`
  position: relative;
  margin: 15px;
  width: calc((100% / 2) - 30px);
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    width: 100%;
  }
`;

export const ModalHowToGet = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: rgba(0, 29, 86, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .card {
    max-width: 552px;
    background: #ffffff;
    border-radius: 20px;
    padding: 39px;
    margin: 0 auto;

    @media only screen and (max-width: 765px) and (min-width: 320px) {
      width: 88%;
      padding-left: 15px;
      padding-right: 15px;
    }
    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      align-items: center;
      margin-bottom: 24px;
      .t1 {
        font-family: 'Rubik-Bold', sans-serif;
        font-size: 20px;
        line-height: 24px;
        color: #001d56;
      }
    }
    .rate {
      .t2 {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #808da6;
      }
    }
    .form {
      .input_div {
        display: flex;

        @media only screen and (max-width: 765px) and (min-width: 320px) {
          flex-direction: column;
        }
        .email,
        .phone {
          width: calc(100% - 50%);
        }
        .email {
          @media only screen and (max-width: 765px) and (min-width: 320px) {
            flex-direction: column;
            display: flex;
            width: auto;
          }
        }

        .phone {
          @media only screen and (max-width: 765px) and (min-width: 320px) {
            flex-direction: column;
            display: flex;
            width: 100%;
          }
          input {
            width: auto;
          }
        }
        input {
          width: 100%;
          &:nth-child(1) {
            margin-right: 7px;
          }
          &:nth-child(2) {
            margin-left: 7px;
          }

          @media only screen and (max-width: 765px) and (min-width: 320px) {
            width: auto;
            margin-bottom: 16px;
            &:nth-child(1) {
              margin-right: 0;
            }
            &:nth-child(2) {
              margin-left: 0;
            }
          }
        }
      }
      textarea {
        display: block;
        width: calc(100% - 36px);
        height: 156px;
      }

      input,
      textarea,
      &::placeholder {
        background: #fafafa;
        border-radius: 12px;
        padding: 12px 18px;
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 15px;
        line-height: 20px;
        color: #808da6;
        border: none;
      }
      .input_div {
        margin-bottom: 16px;

        @media only screen and (max-width: 765px) and (min-width: 320px) {
          margin-bottom: 0;
        }
      }
      textarea {
        margin-bottom: 24px;
      }

      .btn_main {
        display: flex;

        .btn {
          font-family: 'Rubik-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          border-radius: 12px;
          width: 100%;
          padding: 14px;
          transition: 400ms;
        }
        .btn1 {
          background: #ffffff;
          border: 1px solid #f34c35;
          color: #f34c35;
          margin-right: 7px;
          &:hover {
            background: #f34c35;
            color: #ffffff;
          }
        }
        .btn2 {
          background: #ffffff;
          border: 1px solid #001d56;
          color: #001d56;
          margin-left: 7px;
          &:hover {
            background: #001d56;
            color: #ffffff;
          }
        }

        @media only screen and (max-width: 765px) and (min-width: 320px) {
          flex-direction: column;
          .btn1 {
            margin-right: 0;
            margin-bottom: 16px;
          }
          .btn2 {
            margin-left: 0;
          }
        }
      }
    }
  }
`;

export const WhereCanStyle = styled.div`
  padding-top: 64px;
  .where_can_flex {
    display: flex;
    width: 100%;
    min-height: 191px;
    height: 100%;
    margin-top: 24px;

    @media (max-width: 765px) and (min-width: 320px) {
      flex-direction: column;
    }
    img {
      width: 264px;
      min-height: 191px;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      border: 0.1px solid #808da6;
      @media (max-width: 765px) and (min-width: 320px) {
        width: 100%;
        height: 191px;
        margin-bottom: 16px;
      }
    }
    .right {
      width: calc(100% - 264px);
      min-height: 191px;
      margin-left: 24px;
      height: 100%;
      position: relative;
      @media (max-width: 765px) and (min-width: 320px) {
        width: 100%;
        margin-left: 0;
      }

      .text {
        margin-left: 12px;
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #001d56;
      }
      .text2 {
        padding: 0 12px;
        margin-top: 4px;
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #808da6;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .bottom {
        width: calc(100% - 24px);
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fafafa;
        border-radius: 12px;
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        .t1 {
          margin-top: 16px;
          font-family: 'Rubik-Medium', sans-serif;
          font-size: 16px;
          color: #001d56;
          margin: 0;
          margin-left: 5.66px;
          transform: translate(0, -1px);
        }
        .t2 {
          margin-top: 4px;
          font-family: 'Rubik-Regular', sans-serif;
          font-size: 14px;
          color: #808da6;
          margin: 0;
        }
      }
    }
  }
`;

export const BtnMoree = styled.div`
  font-family: 'Rubik-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  border-radius: 12px;
  padding: 8px 0;
  transition: 400ms;
  background: transparent;
  border: none;
  color: #001d56;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
`;
