import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import AppCardImg from '../../images/appcardimg.png';
import { Autoplay, Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { useDispatch, useSelector } from 'react-redux';
import { setCategories, setLoading } from '../../redux';
import { useTranslation } from 'react-i18next';
import {
  AppTitleLeft,
  FourColumnCard,
  FourColumnContainer,
} from '../GlobalStyledComponents';

const Main = styled.div`
  padding-top: 24px;
  user-select: none;
  .swpSub {
    box-sizing: border-box !important;
    padding: 0 calc(50% - 566px) !important;
    width: 100% !important;
  }

  .swp {
    overflow: hidden;
  }

  .card {
    height: 326px;
    border-radius: 20px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: cover;
    }
    .info_main {
      display: flex;
      justify-content: center;
      .info {
        position: absolute;
        bottom: 14px;
        width: calc(100% - 45px);
        background: #ffffff;
        border-radius: 12px;
        padding: 12px;
        .t1 {
          font-family: 'Rubik-Medium', sans-serif;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          color: #001d56;
          display: block;
        }
        .t2 {
          font-family: 'Rubik-Regular', sans-serif;
          font-size: 14px;
          line-height: 20px;
          color: #808da6;
          text-align: center;
          display: block;
        }
      }
    }
  }

  .swpSubContainer {
    width: calc(50% + 850px);
    margin-top: 16px;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    .swpSubContainer {
      width: calc(50% + 700px);
      /* margin-top: 68px;
      margin-bottom: 68px; */
      padding-left: 20px;
    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    .swpSubContainer {
      width: calc(50% + 1050px);
      /* margin-top: 68px;
      margin-bottom: 68px; */
      padding-left: 20px;
    }
  }

  @media (max-width: 768px) and (min-width: 576px) {
    .swpSubContainer {
      width: calc(50% + 1100px);
      /* margin-top: 68px;
      margin-bottom: 68px; */
      padding-left: 20px;
    }
  }

  @media only screen and (max-width: 576px) and (min-width: 320px) {
    .swpSubContainer {
      width: calc(50% + 1400px);
      /* margin-top: 68px;
      margin-bottom: 68px; */
      padding-left: 20px;
    }
  }
`;

export const PropsData = ({ item }) => {
  const { name, villages_count, image, id } = item;
  const { t, i18n } = useTranslation();
  return (
    <Link to={`/catalog/?&category=${id}`}>
      <div className='card'>
        <img src={image} alt='' />
        <div className='info_main'>
          <div className='info'>
            <div className='t1'>{name}</div>
            <div className='t2'>
              {villages_count?.villages_count} {t('component_t.sit')}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Categories = () => {
  const { categories, static_words } = useSelector((state) => state);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const getCategories = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get('api/v1/village/category/')
      .then((res) => {
        let data = res?.data;
        dispatch(setCategories(data));
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    getCategories();
  }, [i18n.language]);

  let getStaticWord = static_words?.find((e) => e?.slug === 'craft');

  return (
    <Main id='categoryy'>
      <AppTitleLeft>
        <div className='container'>
          {/* <p>{t('component_t.category')}</p> */}
          <p>{getStaticWord?.title}</p>
        </div>
      </AppTitleLeft>
      {categories?.length <= 3 ? (
        <>
          <FourColumnContainer
            style={{
              marginTop: '16px',
            }}
          >
            {categories?.map((item, index) => {
              return (
                <FourColumnCard key={index}>
                  <PropsData item={item} />
                </FourColumnCard>
              );
            })}
          </FourColumnContainer>
        </>
      ) : (
        <div
          className='container'
          style={{
            marginTop: '16px',
          }}
        >
          <Swiper
            spaceBetween={24}
            slidesPerView={4}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 24,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
            }}
            modules={[Autoplay, Pagination]}
            className='mySwiper'
          >
            {categories?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <PropsData item={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
      {/* {categories?.length <= 3 ? (
        <>
          <FourColumnContainer
            style={{
              marginTop: '16px',
            }}
          >
            {categories?.map((item, index) => {
              return (
                <FourColumnCard key={index}>
                  <PropsData item={item} />
                </FourColumnCard>
              );
            })}
          </FourColumnContainer>
        </>
      ) : categories?.length <= 4 ? (
        <div
          className='container'
          style={{
            marginTop: '16px',
          }}
        >
          <Swiper
            spaceBetween={10}
            slidesPerView={4}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className='mySwiper'
          >
            {categories?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <PropsData item={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <div className='our-team-swiper-container swp'>
          <div className='swpSub'>
            <div className='swpSubContainer'>
              <Swiper
                spaceBetween={24}
                slidesPerView={5}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className='mySwiper'
              >
                {categories?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <PropsData item={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )} */}
    </Main>
  );
};

export default Categories;
