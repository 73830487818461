import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { setLoading } from "../../redux";
import AppCard from "../AppCard";
import {
  AppTitleLeft,
  FourColumnCard,
  FourColumnContainer,
} from "../GlobalStyledComponents";

const RecentlyViewed = () => {
  const [recentList, setRecentList] = useState([]);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const getRecents = () => {
    dispatch(setLoading(true));
    let recent = window.localStorage.getItem("recent");
    recent = JSON.parse(recent?.length ? recent : "[]");

    let str = "";
    recent.forEach((item) => {
      str += `&id=${item}`;
    });

    if (recent?.length > 0) {
      GetNotAuthInstance()
        .get(`api/v1/village/village/?page=1&page_size=4${str}`)
        .then((res) => {
          if (res.status === 200) {
            const result = res?.data?.results;
            if (Array.isArray(result)) {
              setRecentList(result);
            } else {
              setRecentList([]);
            }
          }
        })
        .catch((error) => {
          setRecentList([]);
        })
        .finally(() => dispatch(setLoading(false)));
    }
  };

  useEffect(() => {
    getRecents();
  }, [i18n.language]);
  return (
    <>
      {recentList?.length > 0 ? (
        <>
          <AppTitleLeft>
            <div className="container">
              <p>{t("component_t.recent_reviews")}</p>
            </div>
          </AppTitleLeft>
          <FourColumnContainer>
            {recentList?.map((item, index) => {
              return (
                <FourColumnCard key={index}>
                  <AppCard item={item} />
                </FourColumnCard>
              );
            })}
          </FourColumnContainer>
        </>
      ) : null}
    </>
  );
};

export default RecentlyViewed;
