import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Breadcrumbs } from '../../components/GlobalStyledComponents';
import {
  Navigation,
  Section10ProgramTour,
  Section11IncludedPrice,
  Section13YandexMapPoints,
  Section1Title,
  Section2Slider,
  Section3About,
  Section4History,
  Section5WhereCan,
  Section6VillageReviews,
  Section7OtherOffers,
  Section8ImportantInfo,
  Section9YandexMap,
} from '../../components/VillageSingle';
import Navigation2 from '../../components/VillageSingle/Navigation2';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { setLoading, setNewTourReview, setTourReviews } from '../../redux';

const Section23Style = styled.div`
  display: flex;
  margin-top: 18px;

  @media (max-width: 870px) and (min-width: 320px) {
    flex-direction: column;
  }
  & > div {
    &:nth-child(1) {
      width: calc(100% - 359px);
      margin-right: 24px;
      @media (max-width: 870px) and (min-width: 320px) {
        margin-right: 0;
        margin-bottom: 24px;
        width: 100%;
      }
    }
    &:nth-child(2) {
      width: 359px;
      @media (max-width: 870px) and (min-width: 320px) {
        width: 100%;
      }
    }
  }
`;
const SectionOthers = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    width: calc(100% - 359px);
    margin-right: 24px;
    @media (max-width: 920px) and (min-width: 320px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const Tour = () => {
  const [tour, setTour] = useState({});
  const [modalTour, setModalTour] = useState(false);
  const [modalThree, setModalThree] = useState(false);
  const [rate, setRate] = useState(0);
  const [obj, setObj] = useState({});
  const [err, setErr] = useState({});
  const [show, setShow] = useState(false);

  const [reviewVisible, setReviewVisible] = useState(3);
  const { static_words } = useSelector((state) => state);

  const { slug } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const anchorTour = [
    {
      name: t('pages.info'),
      url: 'info',
    },
    {
      name: t('pages.about_tour'),
      url: 'tourrr',
    },
    {
      name: t('pages.r_about_tour'),
      url: 'review',
    },
    {
      name: t('pages.map'),
      url: 'map',
    },
    {
      name: t('pages.others'),
      url: 'like',
    },
  ];

  const handleModalTour = () => setModalTour(!modalTour);
  const handleModalThree = () => setModalThree(!modalThree);

  const handleShow = () => {
    if (window.innerWidth > 1152) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const showMoreReview = () => {
    setReviewVisible((pValue) => pValue + 3);
  };

  const getTour = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get(`api/v1/tour/tour/${slug}/`)
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data;
          if (data) {
            setTour(data);
          } else {
            setTour({});
          }
        }
      })
      .catch((error) => setTour({}))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleRate = (v) => {
    setRate(v);
  };

  const handlePushLink = (url) => {
    if (url) {
      navigate(`${url}`);
    }
  };

  const onSubmitReview = (ev) => {
    ev.preventDefault();
    dispatch(setLoading(true));
    let t = true,
      e = {};
    if (!obj.name) {
      t = false;
      e = { ...e, name: true };
    }
    if (!obj.username) {
      t = false;
      e = { ...e, username: true };
    }
    if (!obj.content) {
      t = false;
      e = { ...e, content: true };
    }

    const formData = new FormData();
    formData.append(
      'fullname',
      get(obj, 'username', '') + '' + get(obj, 'name', '')
    );
    formData.append('content', get(obj, 'content', ''));
    formData.append('review', rate === null ? 0 : rate);
    formData.append('tour', Number(tour?.id));

    if (t) {
      GetNotAuthInstance()
        .post('api/v1/tour/review/', formData)
        .then((res) => {
          dispatch(setNewTourReview(res.data));
          setTour({
            ...tour,
            reviews_avg: {
              reviews_avg:
                ((tour?.reviews_avg?.reviews_avg ?? 0) *
                  (tour?.reviews_avg?.reviews_count ?? 1) +
                  rate) /
                ((tour?.reviews_avg?.reviews_count ?? 0) + 1),
              reviews_count: (tour?.reviews_avg?.reviews_count ?? 0) + 1,
            },
            reviews: [
              {
                fullname: get(obj, 'username', '') + '' + get(obj, 'name', ''),
                content: get(obj, 'content', ''),
                review: rate,
                model: 'tour',
              },
              ...(tour?.reviews ?? []),
            ],
          });
          setModalTour(false);
          setObj({});
          window.scroll(0, 0);
        })
        .catch((error) => {})
        .finally(() => dispatch(setLoading(false)));
    } else {
      setErr(e);
      dispatch(setLoading(false));
    }
  };

  const handleChanglePhone = (e) => {
    setObj({ ...obj, phone: e });
  };

  const onSubmitRequest = (ev) => {
    ev.preventDefault();
    dispatch(setLoading(true));
    let t = true,
      e = {};
    if (!obj.name) {
      t = false;
      e = { ...e, name: true };
    }
    if (!obj.username) {
      t = false;
      e = { ...e, username: true };
    }
    if (!obj.email) {
      t = false;
      e = { ...e, email: true };
    }
    if (!obj.comment) {
      t = false;
      e = { ...e, content: true };
    }

    const formData = new FormData();
    formData.append(
      'fullname',
      get(obj, 'username', '') + '' + get(obj, 'name', '')
    );
    formData.append('email', get(obj, 'email', ''));
    formData.append('phone', get(obj, 'phone', ''));
    formData.append('comment', get(obj, 'comment', ''));
    formData.append('tour', Number(tour?.id));

    if (t) {
      GetNotAuthInstance()
        .post('api/v1/tour/user-application/', formData)
        .then((res) => {
          setModalThree(false);
          setObj({});
          window.scroll(0, 0);
        })
        .catch((error) => {})
        .finally(() => dispatch(setLoading(false)));
    } else {
      setErr(e);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getTour();
    handlePushLink();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [i18n.language, slug]);

  useEffect(() => {
    handleShow();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  window.addEventListener('resize', handleShow);

  let getStaticWordvillages = static_words?.find((e) => e?.slug === 'villages');
  let getStaticWordabout_tour = static_words?.find(
    (e) => e?.slug === 'about_tour'
  );
  let getStaticWorditinerary = static_words?.find(
    (e) => e?.slug === 'itinerary'
  );

  let getStaticWordwhat_included = static_words?.find(
    (e) => e?.slug === 'what_included'
  );
  let getStaticWordreviews_about_tour = static_words?.find(
    (e) => e?.slug === 'reviews_about_tour'
  );
  let getStaticWordrec_these_tours_too = static_words?.find(
    (e) => e?.slug === 'rec_these_tours_too'
  );

  return (
    <>
      {/* <Navigation anchor={anchorTour} /> */}

      {show ? (
        <Navigation anchor={anchorTour} />
      ) : (
        <Navigation2 anchor={anchorTour} />
      )}
      <div
        className='container'
        style={{
          paddingTop: '81px',
        }}
      >
        <Breadcrumbs>
          <ul>
            <li>
              <Link to='/'>{t('pages.main')}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <Link to='/catalog'>{getStaticWordvillages?.title}</Link>
            </li>
            {/* <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <Link to={`/catalog/${tour?.village?.slug}`}>
                {tour?.village?.name}
              </Link>
            </li> */}
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <span className='notactive'>{tour?.name}</span>
            </li>
          </ul>
        </Breadcrumbs>
        <Section1Title
          name={tour?.name}
          reviews_avg={
            tour?.reviews_avg?.reviews_avg
              ? tour?.reviews_avg?.reviews_avg
              : tour?.reviews_avg?.reviews_avg == null
              ? 0
              : tour?.reviews_avg?.reviews_avg == undefined
              ? 0
              : tour?.reviews_avg?.reviews_avg == 0
              ? 0
              : null
          }
          reviews_count={tour?.reviews_avg?.reviews_count}
        />
        <Section23Style>
          <Section2Slider images={tour?.images} />
          <Section3About
            description={tour?.description}
            handleModal3={handleModalThree}
            modal3={modalThree}
            setObj={setObj}
            onSubmitRequest={onSubmitRequest}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            info={null}
            section_title={getStaticWordabout_tour?.title}
            secondBtnTitle={t('pages.leav_request')}
            handleChanglePhone={handleChanglePhone}
            location={tour?.location}
          />
        </Section23Style>
        <SectionOthers>
          <Section8ImportantInfo
            address={tour?.address}
            phone={tour?.phone}
            titleSection={t('pages.important_info')}
            idd={'info'}
          />
          <Section4History
            villagee={tour?.about_tour}
            titlee={getStaticWordabout_tour?.title}
            idd1={'tourrr'}
          />
          <Section10ProgramTour
            programs={tour?.programs}
            getStaticWorditinerary={getStaticWorditinerary}
          />
          <Section11IncludedPrice
            services={tour?.services}
            getStaticWordwhat_included={getStaticWordwhat_included}
          />
          <Section6VillageReviews
            reviewss={tour?.reviews}
            handleModal={handleModalTour}
            modal={modalTour}
            setObj={setObj}
            onSubmitReview={onSubmitReview}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
            sectionTitle={getStaticWordreviews_about_tour?.title}
            idd={'review'}
            reviewVisible={reviewVisible}
            showMoreReview={showMoreReview}
          />
          <Section13YandexMapPoints
            titleSection={t('pages.map')}
            points={tour?.points}
            idd={'map'}
          />
        </SectionOthers>
        <Section7OtherOffers
          other={tour?.other_offers}
          handlePushLink={handlePushLink}
          idd={'like'}
          place={'tour'}
          visit_too={getStaticWordrec_these_tours_too?.title}
        />
      </div>
    </>
  );
};

export default Tour;
