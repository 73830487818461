import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Logo from '../images/svg/logo.svg';

const Main = styled.div`
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999;
  user-select: none;
  font-family: 'Rubik-SemiBold', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #001d56;
  .loader_main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    p {
      transform: translate(3px, -1.5px);
      color: #001d56;
      span {
        color: #f34c35;
      }
    }
  }
`;

const OverFlow = createGlobalStyle`
   body{
      overflow: hidden;
   }
`;

const Loading = () => {
  return (
    <Main>
      <div className='loader_main'>
        <p>
          Tour<span>villages</span>...
        </p>
      </div>
      <OverFlow />
    </Main>
  );
};

export default Loading;
