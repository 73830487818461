import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import {
  AppTitleLeft,
  BtnMoree,
  WhereCanStyle,
} from '../GlobalStyledComponents';

const Section5WhereCan = (props) => {
  const {
    titleWhereCan,
    wherecan,
    idd,
    place,
    hotelVisible,
    showMoreHotel,
    restaurantVisible,
    showMoreRestaurant,
    famousVisible,
    showMoreFamous,
  } = props;

  const { t, i18n } = useTranslation();

  return (
    <>
      {wherecan?.length > 0 ? (
        <WhereCanStyle id={`${idd}`}>
          <AppTitleLeft style={{ marginTop: '0' }}>
            <div className='container'>
              <p>{titleWhereCan}</p>
            </div>
          </AppTitleLeft>
          {wherecan
            ?.slice(
              0,
              place === 'hotel'
                ? hotelVisible
                : place === 'restourant'
                ? restaurantVisible
                : place === 'famous'
                ? famousVisible
                : null
            )
            ?.map((item, index) => {
              const { name, slug, description, image, reviews_avg, village } =
                item;
              return (
                <Link
                  to={`/catalog/${village?.slug}/${place}/${slug}`}
                  key={index}
                >
                  <div className='where_can_flex'>
                    <img src={image} alt='' />
                    <div className='right'>
                      <div className=''>
                        <p className='text'>{name}</p>
                        <p className='text2'>{description}</p>
                      </div>
                      <div className=''>
                        <div className='bottom'>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <StarRatings
                              rating={
                                reviews_avg?.reviews_avg
                                  ? reviews_avg?.reviews_avg
                                  : reviews_avg?.reviews_avg == null
                                  ? 0
                                  : null
                              }
                              starRatedColor='#F39035'
                              starDimension='16px'
                              starSpacing='.2px'
                            />
                            <p className='t1'>{reviews_avg?.reviews_avg}</p>
                          </div>
                          <p className='t2'>
                            {reviews_avg?.reviews_count}{' '}
                            {t('component_t.review_count')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </WhereCanStyle>
      ) : null}
      {wherecan?.length > 2 &&
      wherecan?.slice(
        0,
        place === 'hotel'
          ? hotelVisible
          : place === 'restourant'
          ? restaurantVisible
          : place === 'famous'
          ? famousVisible
          : null
      ).length !== wherecan.length ? (
        <BtnMoree
          onClick={
            place === 'hotel'
              ? showMoreHotel
              : place === 'restourant'
              ? showMoreRestaurant
              : place === 'famous'
              ? showMoreFamous
              : null
          }
        >
          {t('component_t.show_again')}
        </BtnMoree>
      ) : null}
    </>
  );
};

export default Section5WhereCan;
