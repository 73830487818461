import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import {
  AppTitleLeft,
  BtnMoree,
  WhereCanStyle,
} from '../GlobalStyledComponents';

const Section5WhereCanTwo = (props) => {
  const {
    titleWhereCan,
    wherecan,
    idd,
    place,
    slugg,
    tourVisible,
    showMoreTour,
    craftVisible,
    showMoreCraft,
  } = props;
  const { t, i18n } = useTranslation();
  return (
    <>
      {wherecan?.length > 0 ? (
        <WhereCanStyle id={`${idd}`}>
          <AppTitleLeft style={{ marginTop: '0' }}>
            <div className='container'>
              <p>{titleWhereCan}</p>
            </div>
          </AppTitleLeft>
          {wherecan
            ?.slice(
              0,
              place === 'tour'
                ? tourVisible
                : place === 'craft'
                ? craftVisible
                : null
            )
            ?.map((item, index) => {
              const { name, slug, description, image, reviews_avg } = item;
              return (
                <Link to={`/catalog/${slugg}/${place}/${slug}`} key={index}>
                  <div className='where_can_flex'>
                    <img src={image} alt='' />
                    <div className='right'>
                      <div className=''>
                        <p className='text'>{name}</p>
                        <p className='text2'>{description}</p>
                      </div>
                      <div className=''>
                        <div className='bottom'>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <StarRatings
                              rating={
                                reviews_avg?.reviews_avg
                                  ? reviews_avg?.reviews_avg
                                  : reviews_avg?.reviews_avg == null
                                  ? 0
                                  : null
                              }
                              starRatedColor='#F39035'
                              starDimension='16px'
                              starSpacing='.2px'
                            />
                            <p className='t1'>{reviews_avg?.reviews_avg}</p>
                          </div>
                          <p className='t2'>
                            {reviews_avg?.reviews_count}{' '}
                            {t('component_t.review_count')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
        </WhereCanStyle>
      ) : null}

      {wherecan?.length > 2 ? (
        <BtnMoree
          onClick={
            place === 'tour'
              ? showMoreTour
              : place === 'craft'
              ? showMoreCraft
              : null
          }
        >
          {t('component_t.show_again')}
        </BtnMoree>
      ) : null}
    </>
  );
};

export default Section5WhereCanTwo;
