import { type } from '@testing-library/user-event/dist/type';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Filter, RFilter } from '../../components/RouteFilter';
import { Breadcrumbs } from '../../components/GlobalStyledComponents';
import { Navigation } from '../../components/Home';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { setLoading } from '../../redux';

const Main = styled.div`
  .t1 {
    margin-bottom: 32px;
    font-family: 'Rubik-Bold', sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #001d56;
    span {
      color: #f34c35;
    }
  }
  .flex {
    display: flex;

    @media (max-width: 815px) and (min-width: 320px) {
      flex-direction: column;
    }
  }
`;
const NoProduct = styled.div``;
const RouteFilter = () => {
  const [modelss] = useState([
    {
      id: 1,
      name: 'fp',
    },
    {
      id: 2,
      name: 'res',
    },
    {
      id: 3,
      name: 'hotel',
    },
    {
      id: 4,
      name: 'craft',
    },
  ]);
  const [routeLatLong, setRouteLatLong] = useState([]);
  const [villages, setVillages] = useState([]);
  const [filteredRouteFilterr, setFilteredRouteFilterr] = useState([]);
  const [nextUrlFilteredRouteFilterr, setNextUrlFilteredRouteFilterr] =
    useState('');
  const [filters, setFilters] = useState({
    model: [],
    village: [],
    is_papular: [],
    r: [],
  });

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { static_words } = useSelector((state) => state);

  const changeFilter = (name = '', value) => {
    let f = { ...filters };
    if (get(f, name, []).includes(value)) {
      let l = [];
      get(f, name, []).forEach((i) => {
        if (i !== value) l.push(i);
      });
      f = { ...f, [name]: l };
    } else {
      f = { ...f, [name]: [...get(f, name, []), value] };
    }
    setFilters(f);
    let query = '/route-filter/?';
    for (const property in f) {
      let s = '';
      f[property].forEach((item) => {
        s += item + ',';
      });
      s = s.slice(0, -1);
      if (s) query += `&${property}=${s}`;
    }
    navigate(query);
  };

  const clearFilter = () => {
    let fff = { ...filters };
    setFilters({ ...get(fff, 'ordering', []) });
    let query = '/route-filter';
    for (const property in fff) {
      let s = '';
      if (property === 'ordering') {
        fff[property].forEach((item) => {
          s += '&ordering=' + item;
        });
        if (s) query += s;
      }
    }
    navigate(query);
  };

  const getFilteredRouteFilterr = (page = 1, next_url = ``) => {
    // const newStr = windowUrl.replaceAll(',', '&model=');

    let newStr = '?';
    const s = [...searchParams];
    s.forEach((item) => {
      const [n, vl] = item;
      const tl = ['model', 'village', 'is_papular', 'r'];
      if (tl.includes(n))
        vl?.split(',').forEach((itemp) => {
          newStr += n + '=' + itemp + '&';
        });
      else newStr = n + '=' + vl + '&';
    });

    dispatch(setLoading(true));
    const urrr = next_url
      ? next_url
      : `api/v1/village/all-services${newStr}page=${page}&page_size=10`;
    GetNotAuthInstance()
      .get(urrr)
      .then((res) => {
        if (res.status === 200) {
          const result =
            page === 1
              ? res?.data?.results
              : [...filteredRouteFilterr, ...res?.data?.results];
          if (Array.isArray(result)) {
            setFilteredRouteFilterr(result);
            setNextUrlFilteredRouteFilterr(res?.data?.next);
          } else {
            setFilteredRouteFilterr([]);
          }
        }
      })
      .catch((err) => {
        setFilteredRouteFilterr([]);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const getVillages = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get('api/v1/village/village/')
      .then((res) => {
        let data = res?.data?.results;
        setVillages(data);
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const onSubmitArr = (id, latitude, longitude) => {
    let getLatLong = window.localStorage.getItem('getLatLong');
    getLatLong = JSON.parse(getLatLong?.length ? getLatLong : '[]');
    setRouteLatLong(getLatLong);
    // if (!getLatLong.includes(id))
    if (!(getLatLong.filter((e) => e.id === id).length > 0))
      getLatLong = [
        ...getLatLong,
        { id: id, latitude: latitude, longitude: longitude },
      ];
    window.localStorage.setItem('getLatLong', JSON.stringify(getLatLong));
  };

  const onSubmitDelete = (id) => {
    let getLatLong = JSON.parse(localStorage.getItem('getLatLong'));
    getLatLong = getLatLong.filter((item) => item?.id !== id);
    setRouteLatLong(getLatLong);
    window.localStorage.setItem('getLatLong', JSON.stringify(getLatLong));
  };

  var strRoute = 'https://yandex.com/maps/?mode=routes&rtext=&rtt=auto&z=7.7';
  let getLatLong = JSON.parse(localStorage.getItem('getLatLong'));
  let mapStr = '';
  getLatLong?.map(
    (item) => (mapStr += `${item?.latitude}%2C${item?.longitude}~`)
  );
  let mappedStr = mapStr.slice(0, -1);
  var searchStr = 'rtext=';

  let resultStr = '';
  if (strRoute.indexOf(searchStr) > -1)
    resultStr =
      strRoute.slice(0, strRoute.indexOf(searchStr) + searchStr.length) +
      '' +
      mappedStr +
      strRoute.slice(strRoute.indexOf(searchStr) + searchStr.length);
  else resultStr = strRoute;

  useEffect(() => {
    getVillages();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [i18n.language]);

  useEffect(() => {
    let f = {};
    const s = [...searchParams];
    s.forEach((item) => {
      const [n, vl] = item;
      const tl = ['model', 'village', 'is_papular', 'r'];
      let vll = [];
      if (tl.includes(n))
        vl?.split(',').forEach((itemp) => {
          vll.push(typeof itemp === 'number' ? parseInt(itemp) : itemp);
        });
      else vll = vl?.split(',');
      if (n && vl) f = { ...f, [n]: vll };
    });
    setFilters(f);
    getFilteredRouteFilterr();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [i18n.language, window.location.search]);

  let getStaticWordCraft = static_words?.find((e) => e?.slug === 'craft');

  let getStaticWordvillages = static_words?.find((e) => e?.slug === 'villages');
  let getStaticWordguest_house = static_words?.find(
    (e) => e?.slug === 'guest_house'
  );
  let getStaticWordcanteen = static_words?.find((e) => e?.slug === 'canteen');

  let getStaticWordsightseeing = static_words?.find(
    (e) => e?.slug === 'sightseeing'
  );

  return (
    <>
      <Navigation />
      <div
        className='container'
        style={{
          paddingTop: '81px',
        }}
      >
        <Breadcrumbs>
          <ul>
            <li>
              <Link to='/'>{t('pages.main')}</Link>
            </li>
            <li>
              <span className='notactive'>\</span>
            </li>
            <li>
              <span className='notactive'>{t('pages.filter_route')}</span>
            </li>
          </ul>
        </Breadcrumbs>
        <Main>
          <div className='t1'>{t('pages.filter_route')}</div>
          <div className='flex'>
            <Filter
              modelss={modelss}
              villages={villages}
              clearFilter={clearFilter}
              filters={filters}
              changeFilter={changeFilter}
              getStaticWordCraft={getStaticWordCraft}
              getStaticWordvillages={getStaticWordvillages}
              getStaticWordguest_house={getStaticWordguest_house}
              getStaticWordcanteen={getStaticWordcanteen}
              getStaticWordsightseeing={getStaticWordsightseeing}
            />
            <RFilter
              resultStr={resultStr}
              onSubmitArr={onSubmitArr}
              onSubmitDelete={onSubmitDelete}
              filtered_item={filteredRouteFilterr}
              nextUrlFilteredRouteFilterr={nextUrlFilteredRouteFilterr}
              getFilteredRouteFilterr={getFilteredRouteFilterr}
            />
          </div>
        </Main>
      </div>
    </>
  );
};

export default RouteFilter;
