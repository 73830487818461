import React from 'react';
import styled from 'styled-components';
import { ModalHowToGet } from '../GlobalStyledComponents';
import Close from '../../images/svg/close.svg';
import Rate from 'rc-rate';
import { useTranslation } from 'react-i18next';

const StyledRate = styled(Rate)`
  &.rc-rate {
    font-size: ${({ size }) => size}px;
  }
`;
const Modal = ({
  handleModal,
  setObj,
  onSubmitReview,
  handleRate,
  obj,
  setErr,
  err,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <ModalHowToGet>
        <div className='card'>
          <div className='header'>
            <p className='t1'>{t('component_t.leave_your_feedback')}</p>
            <img
              src={Close}
              className='cursor'
              onClick={() => {
                handleModal();
                setObj({ ...obj, name: '', username: '', content: '' });
              }}
              alt=''
            />
          </div>
          <form onSubmit={onSubmitReview} className='form'>
            <div className='rate'>
              <p className='t2'>{t('component_t.rate_r')}</p>
              <StyledRate size='38' onChange={handleRate} />
            </div>
            <div className='input_div'>
              <input
                type='text'
                placeholder={t('component_t.name')}
                value={obj.name}
                onChange={(e) => {
                  setObj({ ...obj, name: e.target.value });
                  setErr({ ...err, name: false });
                }}
              />
              <input
                type='text'
                placeholder={t('component_t.l_name')}
                value={obj.username}
                onChange={(e) => {
                  setObj({ ...obj, username: e.target.value });
                  setErr({ ...err, username: false });
                }}
              />
            </div>
            <textarea
              placeholder={t('component_t.y_review')}
              value={obj.content}
              onChange={(e) => {
                setObj({ ...obj, content: e.target.value });
                setErr({ ...err, content: false });
              }}
            ></textarea>

            <div className='btn_main'>
              <button type='submit' className='btn btn1'>
                {t('component_t.send')}
              </button>
              <button
                className='btn btn2'
                onClick={() => {
                  handleModal();
                  setObj({ ...obj, name: '', username: '', content: '' });
                }}
              >
                {t('component_t.cancel')}
              </button>
            </div>
          </form>
        </div>
      </ModalHowToGet>
    </>
  );
};

export default Modal;
