import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import styled from 'styled-components';
import StarRatings from 'react-star-ratings';
import { Link } from 'react-router-dom';
import {
  AppTitleLeft,
  FourColumnCard,
  FourColumnContainer,
} from '../GlobalStyledComponents';
import { useTranslation } from 'react-i18next';

const Top = styled.div`
  padding-top: 64px;
  .swpSub {
    box-sizing: border-box !important;
    padding: 0 calc(50% - 566px);
    width: 100% !important;
  }

  .swp {
    overflow: hidden;
  }
  .swiper {
    padding-bottom: 68px;
  }

  .swpSubContainer {
    width: calc(50% + 850px);
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    .swpSubContainer {
      width: calc(50% + 700px);
      padding-left: 20px;
    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    .swpSubContainer {
      width: calc(50% + 1050px);
      padding-left: 20px;
    }
  }

  @media (max-width: 768px) and (min-width: 576px) {
    .swpSubContainer {
      width: calc(50% + 1100px);
      padding-left: 20px;
    }
  }

  @media only screen and (max-width: 576px) and (min-width: 320px) {
    .swpSubContainer {
      width: calc(50% + 1400px);
      padding-left: 20px;
    }
  }
`;
const Main = styled.div`
  position: relative;
  border-radius: 20px;
  transition: 400ms;
  &:hover {
    background: #fafafa;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  img {
    transition: 400ms;
    width: 100%;
    height: 191px;
    object-fit: cover;
    border-radius: 20px;
  }
  .text {
    margin-left: 12px;
    margin-top: 16px;
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #001d56;
  }
  .text2 {
    padding: 0 12px;
    margin-top: 4px;
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #808da6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .bottom {
    background: #fafafa;
    border-radius: 12px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    .t1 {
      margin-top: 16px;
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 16px;
      color: #001d56;
      margin: 0;
      margin-left: 5.66px;
      transform: translate(0, -1px);
    }
    .t2 {
      margin-top: 4px;
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      color: #808da6;
      margin: 0;
    }
  }
`;

export const PropsData = ({ item, handlePushLink, place }) => {
  const { slug, name, reviews_avg, image, description, village } = item;
  const { t, i18n } = useTranslation();
  return (
    <div
      onClick={() => {
        handlePushLink(
          `/catalog${place ? '/' + village?.slug + '/' + place : ''}/${slug}`
        );
      }}
      className='cursor'
    >
      <Main>
        <img src={image} alt='' />
        <p className='text'>{name}</p>
        <p className='text2'>{description}</p>
        <div className='bottom'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StarRatings
              rating={
                reviews_avg?.reviews_avg
                  ? reviews_avg?.reviews_avg
                  : reviews_avg?.reviews_avg == null
                  ? 0
                  : null
              }
              starRatedColor='#F39035'
              starDimension='16px'
              starSpacing='.2px'
            />
            <p className='t1'>{reviews_avg?.reviews_avg}</p>
          </div>
          <p className='t2'>
            {reviews_avg?.reviews_count} {t('component_t.review_count')}
          </p>
        </div>
      </Main>
    </div>
  );
};

const Section7OtherOffers = ({
  other,
  handlePushLink,
  idd,
  place,
  visit_too,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Top id={`${idd}`}>
      <AppTitleLeft style={{ marginBottom: '24px', marginTop: '0' }}>
        <div className='container'>
          <p>{visit_too}</p>
        </div>
      </AppTitleLeft>

      {other?.length <= 3 ? (
        <>
          <FourColumnContainer>
            {other?.map((item, index) => {
              return (
                <FourColumnCard key={index}>
                  <PropsData
                    item={item}
                    handlePushLink={handlePushLink}
                    place={place}
                  />
                </FourColumnCard>
              );
            })}
          </FourColumnContainer>
        </>
      ) : (
        <div className='container'>
          <Swiper
            spaceBetween={24}
            slidesPerView={4}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 24,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
            }}
            modules={[Autoplay, Pagination]}
            className='mySwiper'
          >
            {other?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <PropsData
                    item={item}
                    handlePushLink={handlePushLink}
                    place={place}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}

      {/* {other?.length <= 3 ? (
        <>
          <FourColumnContainer>
            {other?.map((item, index) => {
              return (
                <FourColumnCard key={index}>
                  <PropsData
                    item={item}
                    handlePushLink={handlePushLink}
                    place={place}
                  />
                </FourColumnCard>
              );
            })}
          </FourColumnContainer>
        </>
      ) : other?.length <= 4 ? (
        <div className='container'>
          <Swiper
            spaceBetween={24}
            slidesPerView={4}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className='mySwiper'
          >
            {other?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <PropsData
                    item={item}
                    handlePushLink={handlePushLink}
                    place={place}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <div className='our-team-swiper-container swp'>
          <div className='swpSub'>
            <div className='swpSubContainer'>
              <Swiper
                spaceBetween={24}
                slidesPerView={4}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className='mySwiper'
              >
                {other?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <PropsData
                        item={item}
                        handlePushLink={handlePushLink}
                        place={place}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      )} */}
    </Top>
  );
};

export default Section7OtherOffers;
