import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/Loading';
import { GetNotAuthInstance } from '../helpers/httpClient';
import { setLoading, setStaticWord } from '../redux';
import Footer from './Footer';

const UserSide = (props) => {
  const { loading } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    getStaticWords();
  }, [i18n.language]);

  const getStaticWords = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get('api/v1/village/static-words/')
      .then((res) => {
        let data = res?.data;
        dispatch(setStaticWord(data));
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <>
      {loading ? <Loading /> : ''}
      <>
        {props.children}
        <Footer />
      </>
    </>
  );
};

export default UserSide;
