import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReadMore from '../../images/readmore.png';

const Main = styled.div`
  padding-top: 64px;
  .t1 {
    font-family: 'Rubik-Bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #001d56;
  }
  .t2 {
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: #001d56;
    position: relative;
    margin-top: 16px;
  }
  .t2active {
    min-height: auto;
  }
  .t2_main {
    position: relative;
  }
  .read_more {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 188px;
    z-index: 2;
  }
  .t2none_active {
    max-height: 238px;
    overflow: hidden;
  }
  .t3 {
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 15px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #001d56;
    cursor: pointer;
    margin-top: 16px;
    display: inline-block;
  }
`;

const Section4History = ({ villagee, titlee, idd1 }) => {
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  const handleShow = () => setShow(!show);

  return (
    <>
      <Main id={`${idd1}`}>
        <p className='t1'>{titlee}</p>
        <div className={show ? 't2active t2_main' : 't2_main t2none_active'}>
          {!show && villagee?.length > 1028 ? (
            <img src={ReadMore} className='read_more' alt='' />
          ) : (
            ''
          )}
          <p className='t2'>{villagee}</p>
        </div>
        {villagee?.length > 1028 ? (
          <p className='t3' onClick={handleShow}>
            {!show ? t('component_t.read_more') : t('component_t.read_less')}
          </p>
        ) : null}
      </Main>
    </>
  );
};

export default Section4History;
