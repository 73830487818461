import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled from 'styled-components';
import RightArrow from '../../images/svg/rightarrow.svg';
import LeftArrow from '../../images/svg/leftarrow.svg';

const Main = styled.div`
  .image-gallery-slides {
    border-radius: 20px 20px 1px 1px;
  }
  .image-gallery-thumbnail {
    width: 25%;
    margin: 0;
    padding: 0;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 104px;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    width: 100%;
    max-height: 432px !important;
    height: 100%;
    object-fit: cover;
  }
  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #f34c35;
  }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px;
    position: relative;
  }

  .image-gallery-left-nav .image-gallery-svg {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('../../images/svg/leftarrow.svg');
      width: 100%;
      height: 100%;
    }
  }
  .image-gallery-right-nav .image-gallery-svg {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('../../images/svg/rightarrow.svg');
    }
  }

  .image-gallery-fullscreen-button,
  .image-gallery-play-button {
    display: none;
  }
`;

const Section2Slider = (props) => {
  const { images } = props;
  const obj = Object.keys(images || {})?.map((item, _) => ({
    original: images[item].image,
    thumbnail: images[item].image,
  }));

  return (
    <Main>
      <ImageGallery items={obj} />
    </Main>
  );
};

export default Section2Slider;
