import React, { useEffect } from "react";
import {
  Categories,
  PopularHandicrafts,
  RecentlyViewed,
  Villages,
  HighlyRatedPlaces,
  Navigation,
} from "../../components/Home";
import Header from "../../components/Header";
import LatestReviews from "../../components/Home/LatestReviews";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { get } from "lodash";

const Home = () => {
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState({
    categories: [],
  });
  const navigate = useNavigate();

  const categories = [
    { id: 1, name: "category 1" },
    { id: 2, name: "category 2" },
    { id: 3, name: "category 3" },
    { id: 4, name: "category 4" },
  ];

  const changeFilter = (name = "", value) => {
    let f = { ...filters };
    if (get(f, name, []).includes(value)) {
      let l = [];
      get(f, name, []).forEach((i) => {
        if (i !== value) l.push(i);
      });
      f = { ...f, [name]: l };
    } else {
      f = { ...f, [name]: [...get(f, name, []), value] };
    }

    setFilters(f);
    let query = "/?";
    for (const property in f) {
      let s = "";
      console.log(property, f[property]);
      f[property].forEach((item) => {
        s += item + ",";
      });
      s = s.slice(0, -1);
      if (s) query += `&${property}=${s}`;
    }
    navigate(query);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return (
    <>
      <Navigation />
      <Header />
      <Villages />
      <Categories />
      <RecentlyViewed />
      <LatestReviews />
      <PopularHandicrafts />
      <HighlyRatedPlaces />
    </>
  );
};

export default Home;
