import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import SingleStar from '../../images/svg/singlestar.svg';
import { Link } from 'react-router-dom';
import {
  AppTitleLeft,
  FourColumnCard,
  FourColumnContainer,
  TwoColumnCard,
  TwoColumnContainer,
} from '../GlobalStyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import moment from 'moment';
import { setLoading, setVillageReviews } from '../../redux';
import Moment from 'react-moment';
import { transform } from 'lodash';

const Main = styled.div`
  background: #fafafa;
  margin-top: 48px;
  padding: 24px 0 48px 0;
  .swpSub {
    box-sizing: border-box !important;
    padding: 0 calc(50% - 566px) !important;
    width: 100% !important;
  }

  .reviews {
    background: #ffffff;
    border: 1px solid #e9ebee;
    border-radius: 20px;
    padding: 24px;
    .desc {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #808da6;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 16px;
      .name {
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #001d56;
      }
      .rate {
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #001d56;
        display: flex;
        align-items: center;
      }
      .title,
      .date {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #808da6;
      }

      .rate,
      .date {
        display: flex;
        justify-content: flex-end;
      }
      .name,
      .rate {
        margin-bottom: 8px;
      }
    }
  }

  .swp {
    overflow: hidden;
  }

  .swpSubContainer {
    width: calc(50% + 1350px);
    margin-top: 24px;
    margin-bottom: 48px;
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    .swpSubContainer {
      width: calc(50% + 1000px);
      padding-left: 20px;
    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    .swpSubContainer {
      width: calc(50% + 1250px);
      padding-left: 20px;
    }
  }

  @media (max-width: 768px) and (min-width: 576px) {
    .swpSubContainer {
      width: calc(50% + 1250px);
      padding-left: 20px;
    }
  }

  @media only screen and (max-width: 576px) and (min-width: 320px) {
    .swpSubContainer {
      padding-left: 20px;
    }
  }
`;

const BtnMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  .aBtn {
    margin-bottom: 24px;
    background: #f34c35;
    border-radius: 8px;
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 14px;
    color: #ffffff;
    padding: 9px 14px;
    width: 330px;
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    @media only screen and (max-width: 576px) and (min-width: 320px) {
      width: 260px;
    }
  }
`;

export const PropsData = ({ item }) => {
  const { fullname, content, review, created_at } = item;
  return (
    <div className='reviews'>
      <p className='desc'>{content}</p>
      <div className='info'>
        <div className=''>
          <p className='name'>{fullname}</p>
          {/* <p className='title'>{findVillage?.name}</p> */}
        </div>
        <div className=''>
          <p className='rate'>
            <img src={SingleStar} alt='' />
            <span style={{ transform: 'translate(3px,1px)' }}>{review}</span>
          </p>
          <p className='date'>
            <Moment format='DD.MM.YYYY'>{created_at}</Moment>
          </p>
        </div>
      </div>
    </div>
  );
};

const LatestReviews = () => {
  const { village_reviews, static_words } = useSelector((state) => state);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const getReviews = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get('api/v1/village/review/?page=1&page_size=6')
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            dispatch(setVillageReviews(result));
          } else {
            dispatch(setVillageReviews([]));
          }
        }
      })
      .catch((error) => {
        dispatch(setVillageReviews([]));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    getReviews();
  }, [i18n.language]);

  let getStaticWordall_reviewsss = static_words?.find(
    (e) => e?.slug === 'all_reviewsss'
  );

  let getStaticWordrecent_reviews = static_words?.find(
    (e) => e?.slug === 'recent_reviews'
  );

  return (
    <>
      {village_reviews?.length > 0 ? (
        <Main>
          <AppTitleLeft>
            <div className='container'>
              <p>{getStaticWordrecent_reviews?.title}</p>
            </div>
          </AppTitleLeft>
          {village_reviews?.length <= 3 ? (
            <>
              <TwoColumnContainer
                style={{
                  marginTop: '16px',
                }}
              >
                {village_reviews?.map((item, index) => {
                  return (
                    <TwoColumnCard key={index}>
                      <PropsData item={item} />
                    </TwoColumnCard>
                  );
                })}
              </TwoColumnContainer>
            </>
          ) : (
            <div
              className='container'
              style={{
                marginTop: '16px',
              }}
            >
              <Swiper
                spaceBetween={24}
                slidesPerView={4}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 24,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                  },
                }}
                modules={[Autoplay, Pagination]}
                className='mySwiper'
              >
                {village_reviews?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <PropsData item={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <BtnMain style={{ textAlign: 'center' }}>
                <Link to='/feedback' className='aBtn'>
                  {getStaticWordall_reviewsss?.title}
                </Link>
              </BtnMain>
            </div>
          )}
          {/* {village_reviews?.length <= 2 ? (
        <>
          <TwoColumnContainer
            style={{
              marginTop: '16px',
            }}
          >
            {village_reviews?.map((item, index) => {
              return (
                <TwoColumnCard key={index}>
                  <PropsData item={item} />
                </TwoColumnCard>
              );
            })}
          </TwoColumnContainer>
        </>
      ) : village_reviews?.length <= 4 ? (
        <div
          className='container'
          style={{
            marginTop: '16px',
          }}
        >
          <Swiper
            spaceBetween={24}
            slidesPerView={3}
            loop={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className='mySwiper'
          >
            {village_reviews?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <PropsData item={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <BtnMain style={{ textAlign: 'center' }}>
            <Link to='/feedback' className='aBtn'>
              {t('component_t.all_reviews')}
            </Link>
          </BtnMain>
        </div>
      ) : (
        <div className='our-team-swiper-container swp'>
          <div className='swpSub'>
            <div className='swpSubContainer'>
              <Swiper
                spaceBetween={24}
                slidesPerView={5}
                loop={true}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className='mySwiper'
              >
                {village_reviews?.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <PropsData item={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <BtnMain style={{ textAlign: 'center' }}>
            <Link to='/feedback' className='aBtn'>
              {t('component_t.all_reviews')}
            </Link>
          </BtnMain>
        </div>
      )} */}
        </Main>
      ) : null}
    </>
  );
};

export default LatestReviews;
