import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppCard from '../AppCard';
import AppCardRouteFilter from '../AppCardRouteFilter';
import {
  ThreeColumnContainer,
  ThreeColumnCard,
} from '../GlobalStyledComponents';

const Main = styled.div`
  width: calc(100% - 264px);
  margin-left: 24px;

  @media (max-width: 815px) and (min-width: 320px) {
    width: 100%;
    margin-left: 0;
  }
  .reultBtnMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .reultBtn {
      font-family: 'Rubik-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      border-radius: 12px;
      display: block;
      padding: 14px;
      transition: 400ms;
      width: 225px;
      background: #f34c35;
      color: #ffffff;
      margin-bottom: 24px;
      text-align: center;
      margin-right: 15px;
    }
    p {
      font-family: 'Rubik-SemiBold', sans-serif;
      font-size: 16px;
      color: #001d56;
      margin-left: 18px;
    }

    @media (max-width: 815px) and (min-width: 320px) {
      flex-direction: column;
      .reultBtn {
        margin-right: 0;
      }
      p {
        margin-bottom: 24px;
        margin-left: 0;
      }
    }
  }

  .btnMain {
    display: flex;
    justify-content: center;
    width: 100%;
    .btn {
      font-family: 'Rubik-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      border-radius: 12px;
      display: block;
      padding: 14px;
      transition: 400ms;
      width: 264px;
      background: #ffffff;
      border: 1px solid #f34c35;
      color: #f34c35;
      margin-top: 24px;
      &:hover {
        background: #f34c35;
        color: #ffffff;
      }
    }
  }
`;
const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #001d56;
`;
const RFilter = (props) => {
  const {
    filtered_item,
    getFilteredRouteFilterr,
    nextUrlFilteredRouteFilterr,
    onSubmitArr,
    onSubmitDelete,
    resultStr,
  } = props;

  const { t, i18n } = useTranslation();

  let getLatLong = window.localStorage.getItem('getLatLong');
  getLatLong = JSON.parse(getLatLong?.length ? getLatLong : '[]');

  return (
    <>
      <Main>
        {resultStr?.length > 58 ? (
          <div className='reultBtnMain'>
            <div className=''>
              <p>
                {t('component_t.choose')} {getLatLong?.length}{' '}
                {t('component_t.route')}
              </p>
            </div>
            <a className='reultBtn' href={resultStr} target='_blank'>
              {t('component_t.result_route')}
            </a>
          </div>
        ) : null}
        <ThreeColumnContainer>
          {filtered_item.length > 0 ? (
            <>
              {filtered_item.map((item, index) => {
                return (
                  <ThreeColumnCard key={index}>
                    <AppCardRouteFilter
                      item={item}
                      onSubmitArr={onSubmitArr}
                      onSubmitDelete={onSubmitDelete}
                    />
                  </ThreeColumnCard>
                );
              })}
            </>
          ) : (
            <NotFound>{t('component_t.v_not_found')}</NotFound>
          )}
        </ThreeColumnContainer>
        <div className='btnMain'>
          {nextUrlFilteredRouteFilterr ? (
            <button
              className='btn'
              onClick={() => {
                getFilteredRouteFilterr(2, nextUrlFilteredRouteFilterr);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              {t('component_t.show_more')}
            </button>
          ) : (
            ''
          )}
        </div>
      </Main>
    </>
  );
};

export default RFilter;
