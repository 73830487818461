import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import styled from 'styled-components';

const Main = styled.div`
  position: relative;
  border-radius: 20px;
  transition: 400ms;
  &:hover {
    background: #fafafa;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  img {
    transition: 400ms;
    width: 100%;
    height: 191px;
    object-fit: cover;
    border-radius: 20px;
  }
  .text {
    margin-left: 12px;
    margin-top: 10px;
    font-family: 'Rubik-Medium', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #001d56;
    min-height: 38px;
  }
  .text2 {
    padding: 0 12px;
    margin-top: 4px;
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #808da6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 60px;
  }
  .text3 {
    padding: 0 12px;
    margin-top: 4px;
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #808da6;
  }
  .bottom {
    background: #fafafa;
    border-radius: 12px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    min-height: 28px;
    .t1 {
      margin-top: 16px;
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 16px;
      color: #001d56;
      margin: 0;
      margin-left: 5.66px;
      transform: translate(0, -1px);
    }
    .t2 {
      margin-top: 4px;
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      color: #808da6;
      margin: 0;
    }
  }
`;
const BtnMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .btn1 {
    background: #001d56;
    border-radius: 12px;
    padding: 12px;
    color: #fff;
    margin: 6px 0 0;
  }
  .btn2 {
    background: #f34c35;
    border-radius: 12px;
    padding: 12px;
    color: #fff;
    margin: 6px 0 0;
  }
`;
const AppCardRouteFilter = ({ item, onSubmitArr, onSubmitDelete }) => {
  const { t, i18n } = useTranslation();

  const { static_words } = useSelector((state) => state);

  let getStaticWordCraft = static_words?.find((e) => e?.slug === 'craft');
  let getStaticWordguest_house = static_words?.find(
    (e) => e?.slug === 'guest_house'
  );
  let getStaticWordcanteen = static_words?.find((e) => e?.slug === 'canteen');
  let getStaticWordsightseeing = static_words?.find(
    (e) => e?.slug === 'sightseeing'
  );

  let getLatLong = window.localStorage.getItem('getLatLong');
  getLatLong = JSON.parse(getLatLong?.length ? getLatLong : '[]');
  return (
    <Main>
      <Link
        // to={`/catalog/${item?.slug}`}
        to={`/catalog/${item?.village?.slug}/${
          item?.model === 'FamousPlaces'
            ? 'famous'
            : item?.model === 'Hotel'
            ? 'hotel'
            : item?.model === 'Restaurant'
            ? 'restourant'
            : item?.model === 'Craft'
            ? 'craft'
            : item?.model
        }/${item?.slug}`}
      >
        <img src={item?.image} alt='' />
        <p className='text3'>
          {item?.model === 'FamousPlaces'
            ? getStaticWordsightseeing?.title
            : item?.model === 'Hotel'
            ? getStaticWordguest_house?.title
            : item?.model === 'Restaurant'
            ? getStaticWordcanteen?.title
            : item?.model === 'Craft'
            ? getStaticWordCraft?.title
            : item?.model}
        </p>
        <p className='text'>{item?.name}</p>
        <p className='text2'>{item?.description}</p>
        <div className='bottom'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StarRatings
              rating={
                item?.reviews_avg?.reviews_avg
                  ? item?.reviews_avg?.reviews_avg
                  : item?.reviews_avg?.reviews_avg == 0
                  ? 0
                  : 0
              }
              starRatedColor='#F39035'
              starDimension='16px'
              starSpacing='.2px'
            />
            <p className='t1'>{item?.reviews_avg?.reviews_avg}</p>
          </div>
          <p className='t2'>
            {item?.reviews_avg?.reviews_count} {t('component_t.review_count')}
          </p>
        </div>
      </Link>
      <BtnMain>
        {!(getLatLong.filter((e) => e.id === item?.unique_id).length > 0) ? (
          <button
            className='btn1'
            onClick={() =>
              onSubmitArr(item?.unique_id, item?.latitude, item?.longitude)
            }
          >
            {t('component_t.add_route')}
          </button>
        ) : (
          <button
            className='btn2'
            onClick={() => onSubmitDelete(item?.unique_id)}
          >
            {t('component_t.delete_route')}
          </button>
        )}
      </BtnMain>
    </Main>
  );
};

export default AppCardRouteFilter;
