import { get } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GetNotAuthInstance } from '../helpers/httpClient';
import HeaderImg from '../images/1mainheaderimg.png';
import { setLoading } from '../redux';

const Main = styled.div`
  height: 480px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  .banner_layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    border-radius: 20px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  p {
    position: relative;
    z-index: 1;
    font-family: 'Rubik-SemiBold', sans-serif;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 48px;
    span {
      color: #f34c35;
    }
    @media only screen and (max-width: 870px) and (min-width: 320px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 26px;
      line-height: 33px;
    }
  }
  .searchform {
    position: relative;
    z-index: 1;
    width: 816px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 6px;
    border-radius: 12px;
    input {
      width: 100%;
      margin-right: 20px;
      margin-left: 18px;
      height: 36px;
      font-family: 'Rubik-Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #8f939a;
      border: none;
    }
    button {
      min-width: 76px;
      background: #f34c35;
      border-radius: 8px;
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 14px;
      color: #ffffff;
    }
    @media only screen and (max-width: 920px) and (min-width: 320px) {
      width: calc(100% - 40px);
    }
  }
  .search_result {
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 99;
    .card {
      width: 100%;
      max-height: 200px;
      overflow: hidden;
      overflow-y: auto;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      .not_found {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #001d56;
        padding: 6px;
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 12px;
        background: #ffffff;
        text-align: center;
      }
      a {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 6px;
        transition: 200ms;
        background: #ffffff;
        img {
          position: relative;
          width: 70px;
          height: 30px;
        }
        span {
          margin-left: 10px;
          font-family: 'Rubik-Regular', sans-serif;
          font-size: 16px;
          line-height: 19px;
          color: #001d56;
        }
        &:hover {
          background: #001d56;
          span {
            color: #ffffff;
          }
        }
      }
      @media only screen and (max-width: 920px) and (min-width: 320px) {
        width: calc(100% - 40px);
      }
    }
  }
`;
const Header = () => {
  const [searchVillage, setSearchVillage] = useState('');
  const [banner, setBanner] = useState({});

  const [searchResult, setSearchResult] = useState([]);
  const [typingTimeOut, setTypingTimeOut] = useState(0);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { static_words } = useSelector((state) => state);

  const getVillageByName = (
    page = 1,
    next_url = `api/v1/village/village/page=${page}&page_size=30`,
    search = ''
  ) => {
    let s = '';
    if (search) {
      s = '&search=' + search;
    }
    if (search !== '') {
      GetNotAuthInstance()
        .get(next_url + s)
        .then((res) => {
          if (res.status === 200) {
            const result = res?.data?.results;
            if (Array.isArray(result)) {
              setSearchResult(result);
            } else {
              setSearchResult([]);
            }
          }
        })
        .catch((err) => {
          setSearchResult([]);
        });
    } else {
      setSearchResult([]);
    }
  };

  const getBanner = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get('api/v1/village/banner/')
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data;
          if (data) {
            setBanner(get(data, '[0]', []));
          } else {
            setBanner({});
          }
        }
      })
      .catch((error) => setBanner({}))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleSearchVillage = (e) => {
    setSearchVillage(e.target.value);
    let page = 1;
    let next_url = `api/v1/village/village/?page=${page}&page_size=30`;
    setTypingTimeOut(
      setTimeout(() => {
        getVillageByName(page, next_url, e.target.value);
      }, 300)
    );

    if (typingTimeOut) {
      clearTimeout(typingTimeOut);
    }
  };

  useEffect(() => {
    getBanner();
    getVillageByName();
  }, [i18n.language]);

  let getStaticWordsearch_by = static_words?.find(
    (e) => e?.slug === 'search_by'
  );

  return (
    <div
      className='container'
      style={{
        paddingTop: '81px',
      }}
    >
      <Main>
        <img src={banner?.image} alt='' />
        <div className='banner_layer' />
        <div dangerouslySetInnerHTML={{ __html: banner?.title }}></div>
        <div className='searchform'>
          <input
            type='text'
            onChange={handleSearchVillage}
            value={searchVillage}
            placeholder={getStaticWordsearch_by?.title}
          />
          {/* <button type='submit'>Найти</button> */}
          <div className='search_result'>
            <div className='card'>
              {searchVillage !== '' && searchResult?.length > 0 ? (
                <>
                  {searchResult?.map((item, index) => {
                    const { slug, image, name } = item;
                    return (
                      <Link to={`/catalog/${slug}`} key={index}>
                        <img src={image} alt='' />
                        <span>{name}</span>
                      </Link>
                    );
                  })}
                </>
              ) : searchVillage !== '' ? (
                <div className='not_found'>
                  {t('component_t.search_v_not_found')}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Main>
    </div>
  );
};

export default Header;
