import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { setLoading } from '../../redux';
import AppCard from '../AppCard';
import {
  AppTitleLeft,
  FourColumnCard,
  FourColumnContainer,
} from '../GlobalStyledComponents';

const HighlyRatedPlaces = () => {
  const [rated, setRated] = useState([]);

  const { t, i18n } = useTranslation();

  const { static_words } = useSelector((state) => state);
  const dispatch = useDispatch();

  const getRated = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get('api/v1/village/village/?popular=1&page=1&page_size=4')
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            setRated(result);
          } else {
            setRated([]);
          }
        }
      })
      .catch((error) => {
        setRated([]);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    getRated();
  }, [i18n.language]);

  let getStaticWordpopular_villages = static_words?.find(
    (e) => e?.slug === 'popular_villages'
  );

  return (
    <>
      <AppTitleLeft>
        <div className='container'>
          <p>{getStaticWordpopular_villages?.title}</p>
        </div>
      </AppTitleLeft>
      <FourColumnContainer>
        {rated.map((item, index) => {
          return (
            <FourColumnCard key={index}>
              <AppCard item={item} />
            </FourColumnCard>
          );
        })}
      </FourColumnContainer>
    </>
  );
};

export default HighlyRatedPlaces;
