import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Map,
  Placemark,
  RulerControl,
  TypeSelector,
  YMaps,
  ZoomControl,
} from 'react-yandex-maps';
import styled from 'styled-components';
import { AppTitleLeft } from '../GlobalStyledComponents';

const Main = styled.div`
  padding-top: 64px;
`;
const Btn = styled.div`
  .btn {
    font-family: 'Rubik-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    border-radius: 12px;
    display: block;
    padding: 14px;
    transition: 400ms;
    width: 264px;
    background: #ffffff;
    border: 1px solid #f34c35;
    color: #f34c35;
    margin-top: 24px;
    text-align: center;
    &:hover {
      background: #f34c35;
      color: #ffffff;
    }
  }
`;
const Section9YandexMap = ({
  titleSection,
  idd,
  location,
  latitude,
  longitude,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Main id={idd}>
        <AppTitleLeft style={{ marginTop: '0', marginBottom: '24px' }}>
          <div className='container'>
            <p>{titleSection}</p>
          </div>
        </AppTitleLeft>
        <YMaps>
          <Map
            state={{ center: [latitude, longitude], zoom: 16 }}
            style={{
              width: '100%',
              height: '372px',
              borderRadius: '20px',
            }}
          >
            <Placemark geometry={[latitude, longitude]} />
            <RulerControl options={{ float: 'right' }} />
            <TypeSelector options={{ float: 'right' }} />
            <ZoomControl options={{ float: 'top' }} />
          </Map>
        </YMaps>
        <Btn>
          <a href={location} target='_blank' className='btn'>
            {t('component_t.how_to_go')}
          </a>
        </Btn>
      </Main>
    </>
  );
};

export default Section9YandexMap;
