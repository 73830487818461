import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AppCard from '../AppCard';
import {
  ThreeColumnContainer,
  ThreeColumnCard,
} from '../GlobalStyledComponents';

const Main = styled.div`
  width: calc(100% - 264px);
  margin-left: 24px;

  @media (max-width: 815px) and (min-width: 320px) {
    width: 100%;
    margin-left: 0;
  }

  .btnMain {
    display: flex;
    justify-content: center;
    width: 100%;
    .btn {
      font-family: 'Rubik-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      border-radius: 12px;
      display: block;
      padding: 14px;
      transition: 400ms;
      width: 264px;
      background: #ffffff;
      border: 1px solid #f34c35;
      color: #f34c35;
      margin-top: 24px;
      &:hover {
        background: #f34c35;
        color: #ffffff;
      }
    }
  }
`;
const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  font-family: 'Rubik-Bold', sans-serif;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #001d56;
`;
const Portfolio = (props) => {
  const { filtered_item, getFilteredVillages, nextUrlFilteredVillages } = props;

  const { t } = useTranslation();

  return (
    <>
      <Main>
        <ThreeColumnContainer>
          {filtered_item.length > 0 ? (
            <>
              {filtered_item.map((item, index) => {
                return (
                  <ThreeColumnCard key={index}>
                    <AppCard item={item} />
                  </ThreeColumnCard>
                );
              })}
            </>
          ) : (
            <NotFound>{t('component_t.v_not_found')}</NotFound>
          )}
        </ThreeColumnContainer>
        <div className='btnMain'>
          {nextUrlFilteredVillages ? (
            <button
              className='btn'
              onClick={() => {
                getFilteredVillages(2, nextUrlFilteredVillages);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              {t('component_t.show_more')}
            </button>
          ) : (
            ''
          )}
        </div>
      </Main>
    </>
  );
};

export default Portfolio;
