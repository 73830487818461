import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';
import Close from '../../images/svg/close.svg';
import { ModalHowToGet } from '../GlobalStyledComponents';
import Modal from './Modal';
import ModalRequest from './ModalRequest';

const Main = styled.div`
  .sub {
    background: #fafafa;
    border-radius: 20px;
    padding: 24px;
    .t1,
    .t3 {
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #001d56;
    }
    .t2 {
      margin-top: 8px;
      margin-bottom: 24px;
      color: #001d56;
    }
    .t2,
    .t4 {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 15px;
      line-height: 20px;
    }
    .t4 {
      text-align: right;
      text-decoration-line: underline;
      color: #808da6;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      &:nth-last-child(1) {
        margin-bottom: 24px;
      }
    }
    .btn {
      font-family: 'Rubik-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      border-radius: 12px;
      display: block;
      width: 100%;
      padding: 14px;
      transition: 400ms;
    }
    .btn1 {
      background: #ffffff;
      border: 1px solid #f34c35;
      color: #f34c35;
      margin-bottom: 14px;
      text-align: center;
      width: auto;
      &:hover {
        background: #f34c35;
        color: #ffffff;
      }
    }
    .btn2 {
      background: #ffffff;
      border: 1px solid #001d56;
      color: #001d56;
      &:hover {
        background: #001d56;
        color: #ffffff;
      }
    }
  }
`;

const OverFlow = createGlobalStyle`
  body{
    overflow: hidden;
  }
`;

const Section3About = ({
  handleModal,
  modal,
  description,
  setObj,
  onSubmitReview,
  handleRate,
  obj,
  setErr,
  err,
  info,
  section_title,
  lenghtHotels,
  lenghtRestaurant,
  handleModal3,
  modal3,
  onSubmitRequest,
  secondBtnTitle,
  handleChanglePhone,
  lenghtFamous,
  location,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Main>
      <div className='sub'>
        <p className='t1'>{section_title}</p>
        <p className='t2'>{description}</p>
        {info === true ? (
          <>
            {lenghtHotels ? (
              <div className='info'>
                <p className='t3'>{t('component_t.where_to_stay')}</p>
                <p className='t4'>{lenghtHotels ? lenghtHotels : 0}</p>
              </div>
            ) : null}
            {lenghtRestaurant ? (
              <div className='info'>
                <p className='t3'>{t('component_t.where_to_eat')}</p>
                <p className='t4'>{lenghtRestaurant ? lenghtRestaurant : 0}</p>
              </div>
            ) : null}
            {lenghtFamous ? (
              <div className='info'>
                <p className='t3'>{t('component_t.where_to_go')}</p>
                <p className='t4'>{lenghtFamous ? lenghtFamous : 0}</p>
              </div>
            ) : null}
            <div className='info'>
              <p className='t3'>{t('component_t.map')}</p>
              <a href='#map' className='t4'>
                {t('component_t.from_map')}
              </a>
            </div>
          </>
        ) : null}
        <a href={location} target='_blank' className='btn btn1'>
          {t('component_t.how_to_go')}
        </a>
        <button
          className='btn btn2'
          onClick={handleModal ? handleModal : handleModal3}
        >
          {secondBtnTitle}
        </button>
      </div>
      {modal ? (
        <Modal
          handleModal={handleModal}
          setObj={setObj}
          onSubmitReview={onSubmitReview}
          handleRate={handleRate}
          obj={obj}
          setErr={setErr}
          err={err}
        />
      ) : null}

      {modal3 ? (
        <ModalRequest
          handleModal3={handleModal3}
          setObj={setObj}
          onSubmitRequest={onSubmitRequest}
          obj={obj}
          setErr={setErr}
          err={err}
          secondBtnTitle={secondBtnTitle}
          handleChanglePhone={handleChanglePhone}
        />
      ) : null}

      {/* {modalTwo ? (
        <ModalHowToGet>
          <div className='card'>
            <div className='header'>
              <p className='t1'>Оставить заявку</p>
              <img
                src={Close}
                className='cursor'
                onClick={handleModalTwo}
                alt=''
              />
            </div>
            <form className='form'>
              <div className='input_div'>
                <input type='text' placeholder='Имя' />
                <input type='text' placeholder='Фамилия' />
              </div>
              <div className='input_div'>
                <input type='text' placeholder='Почта' />
                <input type='text' placeholder='Фамилия' />
              </div>
              <textarea placeholder='Комментарий'></textarea>

              <div className='btn_main'>
                <button type='submit' className='btn btn1'>
                  Отправить
                </button>
                <button className='btn btn2' onClick={handleModalTwo}>
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </ModalHowToGet>
      ) : null} */}
      {modal ? <OverFlow /> : null}
    </Main>
  );
};

export default Section3About;
