import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Main = styled.div`
  padding-top: 64px;
  .t1 {
    font-family: 'Rubik-Bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #001d56;
    margin-bottom: 24px;
  }
  .flex {
    position: relative;
    padding-left: 30px;
    .p1 {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 15px;
      line-height: 20px;
      color: #808da6;
      margin-bottom: 8px;
    }
    .p2 {
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 15px;
      line-height: 20px;
      color: #001d56;
      margin-bottom: 12px;
    }
    .p3 {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 15px;
      line-height: 20px;
      color: #001d56;
      padding-bottom: 24px;
    }
    &::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 0;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #001d56;
    }
    &::after {
      position: absolute;
      content: '';
      top: 4px;
      left: 5.7px;
      width: 1.5px;
      height: 100%;
      background: #001d56;
    }

    &:nth-last-child(1) {
      .p3 {
        margin-bottom: 0;
      }

      &::after {
        width: 0;
        height: 0;
      }
    }
  }
`;
const Section10ProgramTour = ({ programs, getStaticWorditinerary }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Main>
        <p className='t1'>{getStaticWorditinerary?.title}</p>
        {programs?.length > 0 ? (
          <>
            {programs?.map((item, index) => {
              return (
                <div className='flex' key={index}>
                  <p className='p1'>
                    {t('component_t.day')} {index + 1}
                  </p>
                  <p className='p2'>{item?.title}</p>
                  <p className='p3'>{item?.description}</p>
                </div>
              );
            })}
          </>
        ) : null}
      </Main>
    </>
  );
};

export default Section10ProgramTour;
