import React from 'react';
import { useTranslation } from 'react-i18next';

const PageNFound = () => {
  const { t, i18n } = useTranslation();

  return <div>{t('component_t.p_n_found')}</div>;
};

export default PageNFound;
