import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Main = styled.div`
  padding-top: 64px;
  .t1 {
    font-family: 'Rubik-Bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #001d56;
    margin-bottom: 24px;
  }
  .card {
    margin-bottom: 24px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .t2 {
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #808da6;
      margin-bottom: 16px;
    }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #001d56;

      span {
        color: #808da6;
      }

      @media only screen and (max-width: 765px) and (min-width: 320px) {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid #808da6;
        padding-bottom: 4px;
        p {
          margin-bottom: 14px;
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }

        /* &:nth-last-child(1) {
          border-bottom: none;
          padding-bottom: 0;
        } */
      }
    }
  }
`;
const Section12Menu = ({ menus, idd, getStaticWordmenu_canteen }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Main id={idd}>
        <p className='t1'>{getStaticWordmenu_canteen?.title}</p>
        {menus?.map((menu, index) => {
          const { name, childs } = menu;
          return (
            <div className='card' key={index}>
              <p className='t2'>{name}</p>
              {childs?.map((child, index) => {
                const { name, desc, price } = child;
                return (
                  <div className='flex' key={index}>
                    <p className='t3'>
                      {name}
                      <span> ({desc})</span>
                    </p>
                    <p className='t4'>
                      {price} {t('component_t.sum')}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </Main>
    </>
  );
};

export default Section12Menu;
