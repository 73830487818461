import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { PageNFound } from '../components/NotFound';
import { UserSide } from '../layout';
import Login from './Authorization/Login';
import {
  Catalog,
  Home,
  Hotel,
  Village,
  Restaurant,
  FamousPlace,
  Feedbacks,
  Tour,
  RouteFilter,
  Craft,
} from './Public';

const Pages = () => (
  <Routes>
    <Route path='*' element={<PageNFound />} />
    <Route path='/login' element={<Login />} />

    {/* --------------client side route-------------- */}
    <Route
      element={
        <UserSide>
          <Outlet />
        </UserSide>
      }
    >
      <Route path='/'>
        <Route index={true} element={<Home />} />
        {/* <Route index={false} path='profile' element={<Profile />} /> */}
        <Route path='/catalog'>
          <Route index={true} element={<Catalog />} />
          <Route index={false} path=':slug'>
            <Route index={true} element={<Village />} />
            <Route index={false} path='hotel/:slug'>
              <Route index={true} element={<Hotel />} />
            </Route>
            <Route index={false} path='restourant/:slug'>
              <Route index={true} element={<Restaurant />} />
            </Route>
            <Route index={false} path='famous/:slug'>
              <Route index={true} element={<FamousPlace />} />
            </Route>
            <Route index={false} path='tour/:slug'>
              <Route index={true} element={<Tour />} />
            </Route>
            <Route index={false} path='craft/:slug'>
              <Route index={true} element={<Craft />} />
            </Route>
          </Route>
        </Route>
        <Route path='/feedback'>
          <Route index={true} element={<Feedbacks />} />
        </Route>
        <Route path='/route-filter'>
          <Route index={true} element={<RouteFilter />} />
        </Route>
      </Route>
    </Route>
    {/* --------------client side route-------------- */}
  </Routes>
);
export default Pages;
