import React from 'react';
import Moment from 'react-moment';
import styled, { createGlobalStyle } from 'styled-components';
import { AppTitleLeft, BtnMoree } from '../GlobalStyledComponents';
import SingleStar from '../../images/svg/singlestar.svg';
import ArrowDown from '../../images/svg/arrow_down.svg';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';

const Main = styled.div`
  padding-top: 64px;
  .reviews {
    background: #ffffff;
    border: 1px solid #e9ebee;
    border-radius: 20px;
    padding: 24px;
    margin-bottom: 24px;
    .desc {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #001d56;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-top: 16px;
    }
    .info {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .info_sub {
        display: flex;
        & p:nth-child(2) {
          margin-left: 8px;
        }
      }
      .name {
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: #001d56;
      }
      .rate {
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #001d56;
        display: flex;
        align-items: center;
      }
      .date {
        font-family: 'Rubik-Regular', sans-serif;
        font-size: 14px;
        line-height: 17px;
        color: #808da6;
      }

      .rate,
      .date {
        display: flex;
        justify-content: flex-end;
      }
      .name,
      .rate {
        margin-bottom: 8px;
      }
    }
    .title {
      font-family: 'Rubik-Regular', sans-serif;
      font-size: 14px;
      line-height: 17px;
      color: #808da6;
    }
  }

  .btn {
    font-family: 'Rubik-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    border-radius: 12px;
    display: block;
    padding: 14px;
    transition: 400ms;
    width: 264px;
    background: #ffffff;
    border: 1px solid #f34c35;
    color: #f34c35;
    margin-top: 24px;
    &:hover {
      background: #f34c35;
      color: #ffffff;
    }
  }
`;

const OverFlow = createGlobalStyle`
  body{
    overflow: hidden;
  }
`;

const Section6VillageReviews = (props) => {
  const {
    sectionTitle,
    reviewss,
    // getNameById,
    handleModal,
    modal,
    setObj,
    onSubmitReview,
    handleRate,
    obj,
    setErr,
    err,
    idd,
    reviewVisible,
    showMoreReview,
  } = props;

  const { t, i18n } = useTranslation();
  return (
    <>
      <Main id={`${idd}`}>
        <AppTitleLeft style={{ marginTop: '0', marginBottom: '24px' }}>
          <div className='container'>
            <p>{sectionTitle}</p>
          </div>
        </AppTitleLeft>
        {reviewss?.slice(0, reviewVisible)?.map((item, index) => {
          const { fullname, content, review, created_at } = item;
          // const findVillage = getNameById.find((i) => i.id === Number(village));
          return (
            <React.Fragment key={index}>
              <div className='reviews'>
                <div className='info'>
                  <div className='info_sub'>
                    <p className='name'>{fullname}</p>
                    <p className='rate'>
                      <img src={SingleStar} alt='' />
                      <span style={{ transform: 'translate(3px,1px)' }}>
                        {review}
                      </span>
                    </p>
                  </div>
                  <div className=''>
                    <p className='date'>
                      <Moment format='DD.MM.YYYY'>{created_at}</Moment>
                    </p>
                  </div>
                </div>
                {/* <p className='title'>{findVillage?.name}</p> */}
                <p className='desc'>{content}</p>
              </div>
            </React.Fragment>
          );
        })}
        {reviewss?.length > 2 &&
        reviewss?.slice(0, reviewVisible).length !== reviewss.length ? (
          <BtnMoree onClick={showMoreReview}>
            {t('component_t.show_again')}
          </BtnMoree>
        ) : null}
        <button className='btn' onClick={handleModal}>
          {t('component_t.s_review')}
        </button>
        {modal ? (
          <Modal
            handleModal={handleModal}
            setObj={setObj}
            onSubmitReview={onSubmitReview}
            handleRate={handleRate}
            obj={obj}
            setErr={setErr}
            err={err}
          />
        ) : null}
        {modal ? <OverFlow /> : null}
      </Main>
    </>
  );
};

export default Section6VillageReviews;
