import _ from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Map,
  Placemark,
  Polyline,
  RulerControl,
  TypeSelector,
  YMaps,
  ZoomControl,
} from 'react-yandex-maps';
import styled from 'styled-components';
import { AppTitleLeft } from '../GlobalStyledComponents';

const Main = styled.div`
  padding-top: 64px;
`;
const Btn = styled.div`
  .btn {
    font-family: 'Rubik-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    border-radius: 12px;
    display: block;
    padding: 14px;
    transition: 400ms;
    width: 264px;
    background: #ffffff;
    border: 1px solid #f34c35;
    color: #f34c35;
    margin-top: 24px;
    text-align: center;
    &:hover {
      background: #f34c35;
      color: #ffffff;
    }
  }
`;
const Section13YandexMapPoints = ({ titleSection, idd, points }) => {
  const { t, i18n } = useTranslation();
  const pointss = points;
  const mapState = { center: _.get(pointss, '[1]', []), zoom: 16 };

  useEffect(() => {}, [pointss]);
  return (
    <>
      <Main id={idd}>
        <AppTitleLeft style={{ marginTop: '0', marginBottom: '24px' }}>
          <div className='container'>
            <p>{titleSection}</p>
          </div>
        </AppTitleLeft>
        {pointss?.length > 0 ? (
          <YMaps>
            <Map
              state={mapState}
              style={{
                width: '100%',
                height: '372px',
                borderRadius: '20px',
              }}
            >
              <Polyline
                geometry={pointss}
                properties={{
                  hintContent: 'Polyline',
                }}
                options={{
                  fillColor: '#6699ff',
                  interactivityModel: 'default#transparent',
                  strokeWidth: 3,
                  opacity: 1,
                }}
              />
              {_.map(pointss, (item, index) => {
                return <Placemark key={index} geometry={item} />;
              })}
            </Map>
          </YMaps>
        ) : null}
      </Main>
    </>
  );
};

export default Section13YandexMapPoints;
