import { type } from "@testing-library/user-event/dist/type";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Filter, Portfolio } from "../../components/Catalog";
import { Breadcrumbs } from "../../components/GlobalStyledComponents";
import { Navigation } from "../../components/Home";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { setLoading } from "../../redux";
const Main = styled.div`
  .t1 {
    margin-bottom: 32px;
    font-family: "Rubik-Bold", sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #001d56;
    span {
      color: #f34c35;
    }
  }
  .flex {
    display: flex;

    @media (max-width: 815px) and (min-width: 320px) {
      flex-direction: column;
    }
  }
`;
const NoProduct = styled.div``;
const Catalog = () => {
  const [categories, setCategories] = useState([]);
  const [filteredVillages, setFilteredVillages] = useState([]);
  const [nextUrlFilteredVillages, setNextUrlFilteredVillages] = useState("");
  const [filters, setFilters] = useState({
    category: [],
    is_papular: [],
    popular: [],
  });

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { static_words } = useSelector((state) => state);

  const changeFilter = (name = "", value) => {
    let f = { ...filters };
    if (get(f, name, []).includes(value)) {
      let l = [];
      get(f, name, []).forEach((i) => {
        if (i !== value) l.push(i);
      });
      f = { ...f, [name]: l };
    } else {
      f = { ...f, [name]: [...get(f, name, []), value] };
    }
    setFilters(f);
    let query = "/catalog/?";
    for (const property in f) {
      let s = "";
      f[property].forEach((item) => {
        s += item + ",";
      });
      s = s.slice(0, -1);
      if (s) query += `&${property}=${s}`;
    }
    navigate(query);
  };

  const clearFilter = () => {
    let fff = { ...filters };
    setFilters({ ...get(fff, "ordering", []) });
    let query = "/catalog";
    navigate(query);
  };

  const getFilteredVillages = (page = 1, next_url = ``) => {
    const windowUrl = window.location.search;
    const newStr = windowUrl.replaceAll(",", "&category=");
    dispatch(setLoading(true));
    const urrr = next_url
      ? next_url
      : `api/v1/village/village/${newStr ? newStr : ""}${
          newStr ? "&" : "?"
        }page=${page}&page_size=10`;
    GetNotAuthInstance()
      .get(urrr)
      .then((res) => {
        if (res.status === 200) {
          const result =
            page === 1
              ? res?.data?.results
              : [...filteredVillages, ...res?.data?.results];
          if (Array.isArray(result)) {
            setFilteredVillages(result);
            setNextUrlFilteredVillages(res?.data?.next);
          } else {
            setFilteredVillages([]);
          }
        }
      })
      .catch((err) => {
        setFilteredVillages([]);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const getCategories = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/village/category/")
      .then((res) => {
        let data = res?.data;
        setCategories(data);
      })
      .catch((error) => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    let f = {};
    const s = [...searchParams];
    s.forEach((item) => {
      const [n, vl] = item;
      const tl = ["category", "is_papular", "popular"];
      let vll = [];
      if (tl.includes(n))
        vl?.split(",").forEach((itemp) => {
          vll.push(itemp);
        });
      else vll = vl?.split(",");
      if (n && vl) f = { ...f, [n]: vll };
    });
    setFilters(f);
    getFilteredVillages();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [i18n.language, window.location.search]);

  useEffect(() => {
    getCategories();
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  let getStaticWordCraft = static_words?.find((e) => e?.slug === "craft");

  let getStaticWordvillages = static_words?.find((e) => e?.slug === "villages");
  let getStaticWordcatalogue_of_villages = static_words?.find(
    (e) => e?.slug === "catalogue_of_villages"
  );

  return (
    <>
      <Navigation />
      <div
        className="container"
        style={{
          paddingTop: "81px",
        }}
      >
        <Breadcrumbs>
          <ul>
            <li>
              <Link to="/">{t("pages.main")}</Link>
            </li>
            <li>
              <span className="notactive">\</span>
            </li>
            <li>
              <span className="notactive">{getStaticWordvillages?.title}</span>
            </li>
          </ul>
        </Breadcrumbs>
        <Main>
          <div className="t1">{getStaticWordcatalogue_of_villages?.title}</div>
          <div className="flex">
            <Filter
              categories={categories}
              clearFilter={clearFilter}
              filters={filters}
              changeFilter={changeFilter}
              getStaticWordCraft={getStaticWordCraft}
            />
            <Portfolio
              filtered_item={filteredVillages}
              nextUrlFilteredVillages={nextUrlFilteredVillages}
              getFilteredVillages={getFilteredVillages}
            />
          </div>
        </Main>
      </div>
    </>
  );
};

export default Catalog;
