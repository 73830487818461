import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Check from '../../images/svg/check.svg';

const Main = styled.div`
  padding-top: 64px;
  .t1 {
    font-family: 'Rubik-Bold', sans-serif;
    font-size: 20px;
    line-height: 24px;
    color: #001d56;
    margin-bottom: 24px;
  }
  .flex {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 12px;
    img {
      margin-right: 12px;
    }
    p {
      font-family: 'Rubik-Medium', sans-serif;
      font-size: 15px;
      line-height: 20px;
      color: #001d56;
    }
  }
`;
const Section11IncludedPrice = ({ services, getStaticWordwhat_included }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Main>
        <p className='t1'>{getStaticWordwhat_included?.title}</p>
        {services?.length > 0 ? (
          <>
            {services?.map((item, index) => {
              return (
                <div key={index}>
                  <div className='flex'>
                    <img src={Check} alt='' />
                    <p>{item?.title}</p>
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </Main>
    </>
  );
};

export default Section11IncludedPrice;
