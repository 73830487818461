import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GetNotAuthInstance } from '../../helpers/httpClient';
import { setLoading } from '../../redux';
import AppCard from '../AppCard';
import AppCardPopularHandicrafts from '../AppCardPopularHandicrafts';
import {
  AppTitleLeft,
  FourColumnCard,
  FourColumnContainer,
} from '../GlobalStyledComponents';

const PopularHandicrafts = () => {
  const [populars, setPopulars] = useState([]);

  const { t, i18n } = useTranslation();
  const { static_words } = useSelector((state) => state);

  const dispatch = useDispatch();

  const getPopulars = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get('api/v1/village/famous-places/?page=1&page_size=4')
      .then((res) => {
        if (res.status === 200) {
          const result = res?.data?.results;
          if (Array.isArray(result)) {
            setPopulars(result);
          } else {
            setPopulars([]);
          }
        }
      })
      .catch((error) => {
        setPopulars([]);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    getPopulars();
  }, [i18n.language]);

  let getStaticWordsightseeing = static_words?.find(
    (e) => e?.slug === 'sightseeing'
  );

  return (
    <>
      <AppTitleLeft>
        <div className='container'>
          <p>{getStaticWordsightseeing?.title}</p>
        </div>
      </AppTitleLeft>
      <FourColumnContainer>
        {populars.map((item, index) => {
          return (
            <FourColumnCard key={index}>
              <AppCardPopularHandicrafts item={item} />
            </FourColumnCard>
          );
        })}
      </FourColumnContainer>
    </>
  );
};

export default PopularHandicrafts;
