import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Arrow from '../../images/svg/arrow.svg';

const Main = styled.div`
  width: 264px;
  user-select: none;
  @media (max-width: 815px) and (min-width: 320px) {
    width: 100%;
  }
  .filter_card {
    background: #fafafa;
    border-radius: 20px;
    margin-bottom: 24px;
    .filter_dropdown {
      padding: 24px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      cursor: pointer;
      font-family: 'Rubik-SemiBold', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #001d56;
      width: calc(100% - 48px);
      img {
        transition: 200ms;
      }
      .none_active_arrow {
        transform: rotate(180deg);
      }
      p {
        margin-right: 10px;
      }
    }
    .none_active_main {
      transform: scale(0.01);
      opacity: 0;
      height: 0;
      transition: transform 400ms;
      overflow: hidden;
    }
    .active_main {
      transform: scale(1);
      opacity: 1;
      height: auto;
    }
    .filter_main {
      padding: 0 24px 0.1px 24px;
      label {
        width: 100%;
        &:nth-last-child(1) {
          /* .t1{
            mb
         } */
        }
        .radio_input {
          display: none;
          &:checked + .t1 {
            color: #f34c35;
          }
        }
        .t1 {
          font-family: 'Rubik-Regular', sans-serif;
          font-size: 15px;
          line-height: 20px;
          color: #001d56;
          margin-bottom: 15px;
          &:hover {
            cursor: pointer;
            color: #f34c35;
          }
        }
      }
    }
  }

  .btnMain {
    display: flex;
    justify-content: center;
    width: 100%;
    .btn {
      font-family: 'Rubik-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      border-radius: 12px;
      display: block;
      padding: 14px;
      transition: 400ms;
      width: 100%;
      background: #f34c35;
      color: #ffffff;
      margin-bottom: 24px;
    }
  }
`;
const Filter = ({
  categories,
  clearFilter,
  filters,
  changeFilter,
  getStaticWordvillages,
  getStaticWordCraft,
  getStaticWordguest_house,
  getStaticWordcanteen,
  getStaticWordsightseeing,
  getStaticWordtour,
}) => {
  const [filterOne, setFilterOne] = useState(false);
  const [filterTwo, setFilterTwo] = useState(false);

  const { t, i18n } = useTranslation();

  const handleFilterOne = () => setFilterOne(!filterOne);
  const handleFilterTwo = () => setFilterTwo(!filterTwo);
  return (
    <>
      <Main>
        <div className='filter_card'>
          <div className='filter_dropdown' onClick={handleFilterTwo}>
            <p>{getStaticWordCraft?.title}</p>
            <img
              src={Arrow}
              className={!filterTwo ? 'active_arrow' : 'none_active_arrow'}
              alt=''
            />
          </div>
          <div
            className={
              !filterTwo
                ? 'filter_main active_main'
                : 'filter_main none_active_main'
            }
          >
            {categories?.map((item, index) => {
              const { name } = item;
              return (
                <label key={index}>
                  <input
                    type='checkbox'
                    name='portfolio'
                    className='radio_input'
                    onChange={(e) => changeFilter('model', name)}
                    checked={filters?.model?.includes(name)}
                  />
                  <div className='t1'>
                    {name === 'village'
                      ? getStaticWordvillages?.title
                      : name === 'famous_places'
                      ? getStaticWordsightseeing?.title
                      : name === 'restaurant'
                      ? getStaticWordcanteen?.title
                      : name === 'hotel'
                      ? getStaticWordguest_house?.title
                      : name === 'tour'
                      ? getStaticWordtour?.title
                      : null}
                  </div>
                </label>
              );
            })}
          </div>
        </div>
        {Object.keys(filters).length !== 0 ? (
          <div className='btnMain'>
            <button className='btn' onClick={clearFilter}>
              {t('component_t.clear')}
            </button>
          </div>
        ) : null}
      </Main>
    </>
  );
};

export default Filter;
