import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppTitleLeft } from '../GlobalStyledComponents';
import Location from '../../images/svg/location.svg';
import Phone from '../../images/svg/phone.svg';
import { useTranslation } from 'react-i18next';

const Main = styled.div`
  padding-top: 64px;
  .card {
    margin-top: 24px;
    font-family: 'Rubik-Regular', sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #001d56;
    display: flex;
    p {
      width: 346px;
      display: flex;
      align-items: center;
      margin-right: 40px;
      img {
        margin-right: 11px;
      }
    }
    a {
      width: 165px;
      display: flex;
      align-items: center;
      color: #001d56;
      img {
        margin-right: 11px;
      }
    }

    @media only screen and (max-width: 765px) and (min-width: 320px) {
      flex-direction: column;
      p,
      a {
        width: 100%;
      }
      p {
        margin-bottom: 24px;
      }
    }
  }
`;
const Section8ImportantInfo = ({ titleSection, address, phone, idd }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Main id={`${idd}`}>
        <AppTitleLeft style={{ marginTop: '0' }}>
          <div>
            <p>{titleSection}</p>
          </div>
        </AppTitleLeft>
        <div className='card'>
          <p>
            <img src={Location} alt='' /> {address}
          </p>
          <a href={`tel: ${phone}`}>
            <img src={Phone} alt='' /> {phone}
          </a>
        </div>
      </Main>
    </>
  );
};

export default Section8ImportantInfo;
