import React from 'react';
import { ModalHowToGet } from '../GlobalStyledComponents';
import Close from '../../images/svg/close.svg';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';

const ModalRequest = ({
  handleModal3,
  setObj,
  onSubmitRequest,
  obj,
  setErr,
  err,
  secondBtnTitle,
  handleChanglePhone,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <ModalHowToGet>
        <div className='card'>
          <div className='header'>
            <p className='t1'>{secondBtnTitle}</p>
            <img
              src={Close}
              className='cursor'
              onClick={() => {
                handleModal3();
                setObj({
                  ...obj,
                  name: '',
                  username: '',
                  email: '',
                  comment: '',
                });
              }}
              alt=''
            />
          </div>
          <form onSubmit={onSubmitRequest} className='form'>
            <div className='input_div'>
              <input
                type='text'
                placeholder={t('component_t.name')}
                value={obj.name}
                onChange={(e) => {
                  setObj({ ...obj, name: e.target.value });
                  setErr({ ...err, name: false });
                }}
              />
              <input
                type='text'
                placeholder={t('component_t.l_name')}
                value={obj.username}
                onChange={(e) => {
                  setObj({ ...obj, username: e.target.value });
                  setErr({ ...err, username: false });
                }}
              />
            </div>
            <div className='input_div'>
              <input
                type='email'
                placeholder={t('component_t.email')}
                className='email'
                value={obj.email}
                onChange={(e) => {
                  setObj({ ...obj, email: e.target.value });
                  setErr({ ...err, email: false });
                }}
              />
              <PhoneInput
                value={obj.phone}
                onChange={(e) => handleChanglePhone(e)}
                className='phone'
                inputExtraProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: false,
                }}
                // onlyCountries={['uz']}
                // masks={{ uz: '+998 (..) ...-..-..', at: '(....) ...-....' }}
                // masks={{ uz: '(...) ..-..-..' }}
                masks='+998 .. ... .. ..'
                autocomplete='off'
                placeholder={'+998 .. ... .. ..'}
                defaultCountry='uz'
                enableAreaCodeStretch
                // containerStyle={{ marginTop: '15px' }}
                // searchClass='search-class'
                // searchStyle={{ margin: '0', width: '97%', height: '30px' }}
                // enableSearchField
                // disableSearchIcon
              />
            </div>
            <textarea
              placeholder={t('component_t.comment')}
              value={obj.comment}
              onChange={(e) => {
                setObj({ ...obj, comment: e.target.value });
                setErr({ ...err, comment: false });
              }}
            ></textarea>

            <div className='btn_main'>
              <button type='submit' className='btn btn1'>
                {t('component_t.send')}
              </button>
              <button
                className='btn btn2'
                onClick={() => {
                  handleModal3();
                  setObj({
                    ...obj,
                    name: '',
                    username: '',
                    email: '',
                    comment: '',
                  });
                }}
              >
                {t('component_t.cancel')}
              </button>
            </div>
          </form>
        </div>
      </ModalHowToGet>
    </>
  );
};

export default ModalRequest;
